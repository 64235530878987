import { I18nMessage } from '@apoly-42/apoly-components';
import { ListWithBulletPoints } from '@apoly-42/material-ui';
import { faCheck } from '@fortawesome/pro-solid-svg-icons';
import { Grid, withStyles } from '@material-ui/core';
import PropTypes from 'prop-types';
import React from 'react';
import { defineMessages } from 'react-intl';
import { compose } from 'recompose';
import { commonMessages } from '../../../constants/messages/commonMessages';
import { BRAND_NAME } from '../../../environment';
import FooterHeadline from './FooterHeadline';

const messages = defineMessages({
  MadeInGermany: {
    id: 'MadeInGermany',
    defaultMessage: 'made in germany',
  },
  highestAvailability: {
    id: 'highestAvailability',
    defaultMessage: 'highest availability',
  },
});

export const secureWithBrand = [
  <I18nMessage message={commonMessages.germanServer} />,
  <I18nMessage message={messages.MadeInGermany} />,
  <I18nMessage message={commonMessages.sslSecureConnection} />,
  <I18nMessage message={messages.highestAvailability} />,
];

const styles = theme => ({
  listItem: theme.typography.body1,
});

const InnerSafeWithBrand = props => (
  <Grid container spacing={16}>
    <Grid item xs={12}>
      <FooterHeadline>
        <I18nMessage
          message={commonMessages.safeWithApoly}
          values={{ brand: BRAND_NAME }}
        />
      </FooterHeadline>
    </Grid>
    <Grid item xs={12} style={{ color: '#fff' }}>
      <ListWithBulletPoints
        listArray={secureWithBrand}
        icon={faCheck}
        listItemTextClassName={props.classes.listItem}
      />
    </Grid>
  </Grid>
);

const enhance = compose(withStyles(styles));

const SafeWithBrand = enhance(InnerSafeWithBrand);

InnerSafeWithBrand.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default SafeWithBrand;
