import { I18nMessage } from '@apoly-42/apoly-components';
import {
  createContainer,
  onPropsDidUpdate,
  onWillUnmount,
} from '@apoly-42/apoly-utils';
import PropTypes from 'prop-types';
import { cond, last, pipe, sort } from 'ramda';
import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { withHandlers } from 'recompose';
import { commonMessages } from '../../constants/messages/commonMessages';
import {
  clearSearchFilterOptions,
  setSymptomsFilter,
} from '../../redux/medicSearchFilter/reducer';
import { selectBasePath } from '../../redux/shopPharmacy/reducer';
import {
  medicSearchSetPharmacyPath,
  singleProductSetPharmacyPath,
} from '../../routes/paths';
import {
  searchArticles,
  searchSymptomsQuickSearch,
} from '../../utilities/apis/apolyApi/apolyApi';
import {
  denormalizeArticleResponse,
  denormalizeMedicinesFromArticleResponse,
} from '../../utilities/apis/apolyApi/schemas/searchArticlesResponseSchema';
import googleDataLayerPush from '../../utilities/googleDataLayerPush';
import {
  articlesForMedicineId,
  getImageForProduct,
} from '../../utilities/product/productUtilities';

const mapSuggestionToEvent = suggestion => ({
  event: 'productClick',
  ecommerce: {
    currencyCode: 'EUR',
    click: {
      actionField: { list: 'Shop-Such-Leiste' },
      products: [
        {
          name: suggestion.name,
          id: suggestion.name,
          brand: suggestion.producers.map(producer => producer.name).join(','),
          category: suggestion.symptoms.map(symptom => symptom.name).join(','),
          position: suggestion.index,
        },
      ],
    },
  },
});

const getArticlePath = (basePath, pharmacyPackage) =>
  singleProductSetPharmacyPath(
    basePath,
    pharmacyPackage.medicine.urlCode,
    pharmacyPackage.urlCode
  );

const mostExpensiveArticle = pipe(
  sort((a, b) => a.price - b.price),
  last
);

const getArticleSpecificInformationForMedicine = basePath => result =>
  result.medicines.map(medicine => {
    const articles = articlesForMedicineId(
      result.articles,
      medicine.medicineId
    );

    const availableArticles = articles.filter(
      article => article.availability === 'available'
    );

    const article = mostExpensiveArticle(
      availableArticles.length === 0 ? articles : availableArticles
    );

    return {
      ...medicine,
      isProduct: true, // to make sure selected Suggestion is a Product not a Symptom
      url: getArticlePath(basePath, article.package),
      image: getImageForProduct(
        article.package.hasApoPixPicture,
        article.package.pzn
      ),
    };
  });

const getMultiSuggests = (medicines, symptoms) => [
  {
    title: <I18nMessage message={commonMessages.medicines} />,
    suggestions: medicines.map((medicine, index) => ({ ...medicine, index })),
  },
  {
    title: <I18nMessage message={commonMessages.symptoms} />,
    suggestions: symptoms,
  },
];

const searchForArticlesSuggestions = (pharmacyId, searchTerm, basePath) =>
  searchArticles(pharmacyId, {
    medicineName: searchTerm,
    medicineLimit: 3,
  })
    .then(({ result: { articles: articleIds }, entities }) => ({
      medicines: denormalizeMedicinesFromArticleResponse(
        entities,
        Object.keys(entities.medicines)
      ).medicines,
      articles: denormalizeArticleResponse(entities, articleIds).articles,
    }))
    .then(getArticleSpecificInformationForMedicine(basePath));

const SearchAutosuggestContainer = createContainer(
  connect(
    state => ({ basePath: selectBasePath(state) }),
    { setSymptomsFilter, clearSearchFilterOptions }
  ),
  withRouter,
  withHandlers(() => {
    let globalTimeoutId;
    let suggestionSelected;
    let handleEnterTimeoutId;

    return {
      handleSuggestionsFetchRequested: props => () => {
        const timeoutId = setTimeout(() => {
          if (globalTimeoutId === timeoutId) {
            Promise.all([
              searchForArticlesSuggestions(
                props.pharmacyId,
                props.fetchValue,
                props.basePath
              ),
              searchSymptomsQuickSearch(props.fetchValue, { limit: 3 }),
            ]).then(([medicines, symptoms]) =>
              props.setSuggestions(getMultiSuggests(medicines, symptoms))
            );
          }
        }, 300);

        globalTimeoutId = timeoutId;
      },
      redirectToSuggestion: props => () => {
        suggestionSelected = true; // this flag is checked in handleEnter, cause this two events runs parallel
        googleDataLayerPush(mapSuggestionToEvent(props.selectedSuggestion));
        // eslint-disable-next-line no-unused-expressions
        props.closeSearchBar && props.closeSearchBar();
        props.history.push(props.selectedSuggestion.url);
      },
      handleEnter: props => () => {
        // timeout um sichertzustellen dass suggestion-klick zuerst gehandlet wird
        // falls bereits eine suggestion selektiert wurde, nicht weiter machen => man soll direkt zur
        // produkt-unterseite kommen und nicht erst zur suche wenn man direkt auf eine suggestion geklickt hat
        handleEnterTimeoutId = setTimeout(
          () => [
            !suggestionSelected &&
              props.onEnter &&
              props.onEnter(props.searchValue),
            !suggestionSelected &&
              props.searchValue &&
              !props.onEnter && [
                props.closeSearchBar && props.closeSearchBar(),
                props.setRedirectToMedicSearchPage(true),
              ],
          ],
          50
        );
      },
      handleChooseSymptom: props => selectedSymptom => {
        suggestionSelected = true;
        // eslint-disable-next-line no-unused-expressions
        props.closeSearchBar && props.closeSearchBar();
        props.clearSearchFilterOptions();
        props.setSymptomsFilter(selectedSymptom.symptomId);
        props.history.push(medicSearchSetPharmacyPath(props.basePath), {
          searchKeyword: '',
        });
      },
      handleComponentUnMount: () => () => [
        clearTimeout(globalTimeoutId),
        clearTimeout(handleEnterTimeoutId),
      ],
    };
  }),
  onPropsDidUpdate(['fetchValue'], props =>
    props.handleSuggestionsFetchRequested()
  ),
  onPropsDidUpdate(
    ['selectedSuggestion'],
    cond([
      [props => !props.selectedSuggestion, () => false],
      [
        props => props.selectedSuggestion.isProduct,
        props => props.redirectToSuggestion(),
      ],
      [
        props => props.selectedSuggestion.symptomId,
        props => props.handleChooseSymptom(props.selectedSuggestion),
      ],
    ])
  ),
  onPropsDidUpdate(['searchForMedic'], props => props.handleEnter()),
  onWillUnmount(props => props.handleComponentUnMount())
);

SearchAutosuggestContainer.propTypes = {
  fetchValue: PropTypes.any.isRequired,
  searchValue: PropTypes.any.isRequired,
  searchForMedic: PropTypes.string.isRequired,
  pharmacyId: PropTypes.number.isRequired,
  basePath: PropTypes.string.isRequired,
  selectedSuggestion: PropTypes.object,
  onEnter: PropTypes.func,
  closeSearchBar: PropTypes.func,
  setSearchValue: PropTypes.func.isRequired,
  setRedirectToMedicSearchPage: PropTypes.func.isRequired,
  setSuggestions: PropTypes.func.isRequired,
};

SearchAutosuggestContainer.defaultProps = {
  onEnter: null,
  closeSearchBar: null,
  selectedSuggestion: null,
};

export default SearchAutosuggestContainer;
