import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ListItem, ListItemIcon, ListItemText } from '@material-ui/core';
import PropTypes from 'prop-types';
import React from 'react';

const ListItemButtonWithIcon = ({
  text,
  icon,
  onClickEvent,
  secondaryText,
}) => (
  <ListItem button onClick={onClickEvent} divider>
    <ListItemIcon>
      <FontAwesomeIcon icon={icon} />
    </ListItemIcon>
    <ListItemText primary={text} secondary={secondaryText} />
  </ListItem>
);

ListItemButtonWithIcon.propTypes = {
  icon: PropTypes.object.isRequired,
  text: PropTypes.any.isRequired,
  secondaryText: PropTypes.any,
  onClickEvent: PropTypes.func.isRequired,
};

ListItemButtonWithIcon.defaultProps = {
  secondaryText: '',
};

export default ListItemButtonWithIcon;
