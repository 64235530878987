import { withToggleState } from '@apoly-42/apoly-components';
import { switchF } from '@apoly-42/apoly-utils';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { compose } from 'recompose';
import LogOutContainer from '../../../../containers/auth/LogOutContainer';
import { getPharmacyTheme } from '../../../../pharmacy-theme/pharmacyTheme';
import {
  selectBasePath,
  selectIsPostal,
  selectPharmacy,
} from '../../../../redux/shopPharmacy/reducer';
import NavBarSetPharmacyViewPostal from './NavBarSetPharmacyPostal';
import NavBarSetPharmacyView from './NavBarSetPharmacyView';
import NavBarView from './NavBarView';

const getNavbarComponent = (pharmacy, pharmacyTheme, isPostal, setLogOut) =>
  switchF(
    [() => !pharmacy, () => <NavBarView logOut={setLogOut} />],
    [
      () => pharmacy && pharmacyTheme && pharmacyTheme.customNavBarComponent,
      () => (
        <pharmacyTheme.customNavBarComponent
          pharmacyTheme={pharmacyTheme}
          logOut={setLogOut}
        />
      ),
    ],
    [
      () => pharmacy && !isPostal,
      () => <NavBarSetPharmacyView logOut={setLogOut} />,
    ],
    [
      () => pharmacy && isPostal,
      () => <NavBarSetPharmacyViewPostal logOut={setLogOut} />,
    ]
  );

const InnerNavBar = props => {
  const pharmacyTheme = props.pharmacy && getPharmacyTheme(props.pharmacy);
  return (
    <React.Fragment>
      <LogOutContainer
        basePath={props.basePath}
        logOut={props.logOut}
        setLogOut={props.setLogOut}
        currentPath={props.location && props.location.pathname}
      />

      {getNavbarComponent(
        props.pharmacy,
        pharmacyTheme,
        props.isPostal,
        props.setLogOut
      )}
    </React.Fragment>
  );
};

const mapStateToProps = state => ({
  basePath: selectBasePath(state),
  isPostal: selectIsPostal(state),
  pharmacy: selectPharmacy(state),
});

const enhance = compose(
  withToggleState('logOut', 'setLogOut', false),
  withRouter,
  connect(mapStateToProps)
);

const NavBar = enhance(InnerNavBar);

InnerNavBar.propTypes = {
  logOut: PropTypes.bool.isRequired,
  isPostal: PropTypes.bool.isRequired,
  basePath: PropTypes.string.isRequired,
  setLogOut: PropTypes.func.isRequired,
  location: PropTypes.object,
  pharmacy: PropTypes.object,
};

InnerNavBar.defaultProps = {
  pharmacy: null,
  location: null,
};

export default NavBar;
