import { faCamera } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Hidden, IconButton, Toolbar } from '@material-ui/core';
import PropTypes from 'prop-types';
import React from 'react';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import Link from 'react-router-dom/Link';
import { compose } from 'recompose';
import { commonMessages } from '../../../../constants/messages/commonMessages';
import { selectBasePath } from '../../../../redux/shopPharmacy/reducer';
import { submitPrescriptionPath } from '../../../../routes/paths';
import DeliveryTimeSpanTooltip from './subNavBarComponents/DeliveryTimeSpanTooltip';
import OpeningTimeTooltip from './subNavBarComponents/OpeningTimeTooltip';
import PharmacyInfoSubNavBar from './subNavBarComponents/PharmacyInfoSubNavBar';

const getStyleIfHighlighted = isOpen => ({
  backgroundColor: isOpen ? '#fff' : '',
});
const getColorIfHighlighted = isOpen => (isOpen ? 'primary' : 'inherit');

const InnerSubNavBar = ({
  enhancedPharmacy,
  basePath,
  intl: { formatMessage },
}) => (
  <Toolbar style={{ flexGrow: 1 }}>
    <PharmacyInfoSubNavBar />
    <div>
      <Hidden smDown>
        {enhancedPharmacy && (
          <OpeningTimeTooltip
            color={getColorIfHighlighted(enhancedPharmacy.isOpen)}
            style={getStyleIfHighlighted(enhancedPharmacy.isOpen)}
            enhancedPharmacy={enhancedPharmacy}
          />
        )}
      </Hidden>
      <Hidden mdUp>
        <IconButton
          style={{ marginLeft: 8, marginRight: 8 }}
          color="inherit"
          component={Link}
          aria-label={formatMessage(commonMessages.submitPrescription)}
          to={submitPrescriptionPath(basePath)}
        >
          <FontAwesomeIcon icon={faCamera} />
        </IconButton>
      </Hidden>
      {enhancedPharmacy && (
        <DeliveryTimeSpanTooltip
          color={getColorIfHighlighted(enhancedPharmacy.isOpen)}
          style={getStyleIfHighlighted(enhancedPharmacy.isOpen)}
          enhancedPharmacy={enhancedPharmacy}
        />
      )}
    </div>
  </Toolbar>
);

const mapStateToProps = state => ({
  basePath: selectBasePath(state),
});

const enhance = compose(
  connect(mapStateToProps),
  injectIntl
);

const SubNavBar = enhance(InnerSubNavBar);

SubNavBar.propTypes = {
  pharmacy: PropTypes.object.isRequired,
  enhancedPharmacy: PropTypes.object,
};

SubNavBar.defaultProps = {
  enhancedPharmacy: null,
};

InnerSubNavBar.propTypes = {
  basePath: PropTypes.string.isRequired,
  ...SubNavBar.propTypes,
};

InnerSubNavBar.defaultProps = {
  ...SubNavBar.defaultProps,
};

export default SubNavBar;
