import { I18nMessage } from '@apoly-42/apoly-components';
import {
  Divider,
  Hidden,
  MuiThemeProvider,
  withStyles,
} from '@material-ui/core';
import { css } from 'emotion';
import PropTypes from 'prop-types';
import React from 'react';
import { defineMessages } from 'react-intl';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { flexAlignCenter } from '../../../constants/styles/commonClasses';
import {
  appGrid,
  contentContainer,
  contentScreen,
  lgUp,
  mdUp,
  smUp,
} from '../../../constants/styles/cssEmotionClasses';
import { selectBasePath } from '../../../redux/shopPharmacy/reducer';
import ChangeLocale from '../../ChangeLocale';
import { createAppMuiTheme } from '../AppMuiTheme';
import AboutCompany from './AboutCompany';
import BecomePartner from './BecomePartner';
import Blog from './Blog';
import FootNotes from './FootNotes';
import LawAgreements from './LawAgreements';
import MoreInformation from './MoreInformation';
import PossiblePaymentMethods from './PossiblePaymentMethods';
import SafeWithBrand from './SafeWithBrand';
import SocialMedia from './SocialMedia';

const theme = createAppMuiTheme({
  palette: {
    type: 'dark', // Switching the dark mode on is a single property value change.
  },
});

const styles = {
  footer: {
    backgroundColor: theme.palette.grey[900],
  },
};

const messages = defineMessages({
  followUs: {
    id: 'followUs',
    defaultMessage: 'Follow us now!',
  },
});

const footerElement = css`
  padding: 16px 8px;
  ${mdUp} {
    padding: 24px 8px;
  }
`;

const footerGrid = css`
  ${appGrid};
  ${contentContainer};
  grid-template-columns: 1fr;
  ${smUp} {
    grid-template-columns: repeat(2, 1fr);
  }
  ${lgUp} {
    grid-template-columns: repeat(4, 1fr);
  }
`;

const dividerInGrid = css`
  ${appGrid};
  grid-column: span 1;
  ${smUp} {
    grid-column: span 2;
  }
  ${mdUp} {
    grid-column: span 4;
  }
`;

const FooterDivider = () => (
  <div className={dividerInGrid}>
    <Divider />
  </div>
);

const InnerFooterPage = ({ basePath, classes }) => (
  <MuiThemeProvider theme={theme}>
    <div className={classes.footer}>
      <div className={footerGrid}>
        <div className={footerElement}>
          <ChangeLocale />
        </div>
        <Hidden smUp>
          <FooterDivider />
        </Hidden>
        <div className={footerElement}>
          <MoreInformation basePath={basePath} />
        </div>
        <Hidden lgUp>
          <FooterDivider />
        </Hidden>
        <div className={footerElement}>
          <Blog />
        </div>
        <Hidden smUp>
          <FooterDivider />
        </Hidden>
        <div className={footerElement}>
          <PossiblePaymentMethods />
        </div>
        <FooterDivider />
        <div className={footerElement}>
          <AboutCompany />
        </div>
        <Hidden smUp>
          <FooterDivider />
        </Hidden>
        <div className={footerElement}>
          <SafeWithBrand />
        </div>
        <Hidden lgUp>
          <FooterDivider />
        </Hidden>
        <div className={footerElement}>
          <BecomePartner />
        </div>
        <Hidden smUp>
          <FooterDivider />
        </Hidden>
        <div className={footerElement}>
          <SocialMedia title={<I18nMessage message={messages.followUs} />} />
        </div>
      </div>
      <div className={contentScreen('80px')} style={flexAlignCenter}>
        <LawAgreements basePath={basePath} />
      </div>
      <div className={contentContainer} style={{ paddingBottom: 16 }}>
        <FootNotes />
      </div>
    </div>
  </MuiThemeProvider>
);

const mapStateToProps = state => ({
  basePath: selectBasePath(state),
});

const enhance = compose(
  withStyles(styles),
  connect(mapStateToProps)
);

const FooterPage = enhance(InnerFooterPage);

InnerFooterPage.propTypes = {
  classes: PropTypes.object.isRequired,
  basePath: PropTypes.string,
};

InnerFooterPage.defaultProps = {
  basePath: '',
};

export default FooterPage;
