import { faSearch } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, withStyles } from '@material-ui/core';
import cn from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import { defineMessages, injectIntl } from 'react-intl';
import { compose } from 'recompose';
import {
  buttonXs,
  verticalCenteredInFlex,
} from '../../../../../constants/styles/commonClasses';

const styles = {
  verticalCenteredInFlex,
  buttonXs,
};

const messages = defineMessages({
  toggleSearchBar: {
    id: 'toggleSearchBar',
    defaultMessage: 'toggle search bar',
  },
});

const FindMedicButton = ({
  classes,
  toggleSearchBar,
  disabled,
  intl: { formatMessage },
}) => (
  <Button
    className={cn(classes.verticalCenteredInFlex, classes.buttonXs)}
    disabled={disabled}
    color="inherit"
    aria-label={formatMessage(messages.toggleSearchBar)}
    onClick={toggleSearchBar}
  >
    <span>
      <FontAwesomeIcon icon={faSearch} />
    </span>
  </Button>
);

FindMedicButton.propTypes = {
  intl: PropTypes.object.isRequired,
  classes: PropTypes.object.isRequired,
  disabled: PropTypes.bool.isRequired,
  toggleSearchBar: PropTypes.func.isRequired,
};

const enhance = compose(
  withStyles(styles),
  injectIntl
);

export default enhance(FindMedicButton);
