import { I18nMessage } from '@apoly-42/apoly-components';
import {
  ButtonWithIcon,
  DefaultError,
  ShowComponentWhileLoading,
  ListWithBulletPoints,
} from '@apoly-42/material-ui';
import { faChevronDoubleRight } from '@fortawesome/pro-regular-svg-icons';
import { faCheck } from '@fortawesome/pro-solid-svg-icons';
import { Card, CardContent, CardMedia, Hidden } from '@material-ui/core';
import PropTypes from 'prop-types';
import React from 'react';
import { defineMessages, injectIntl } from 'react-intl';
import { Link } from 'react-router-dom';
import { compose, withState } from 'recompose';
import defaultPic from '../../../../constants/assets/germanTownImgs/berlin-skyline-fallback.png';
import happyPill from '../../../../constants/assets/Pille-Nurse-Stimmung-happy.png';
import {
  flex,
  flexAlignFlexStart,
} from '../../../../constants/styles/commonClasses';
import {
  gridArea,
  pharmacyCtaGrid,
} from '../../../../constants/styles/cssEmotionClasses';
import LoadGermanCityPicturesLoadable from '../../../../containers/loadGermanCityPictures/LoadGermanCityPicturesLoadable';
import LoadPharmaciesByZip from '../../../../containers/loadPharmacies/LoadPharmaciesByZip';
import { BRAND_NAME } from '../../../../environment';
import { choosePharmacyPath } from '../../../../routes/paths';
import ContactNoPartnerPharmacyButton from './ContactNoPartnerPharmacyButton';
import NoPartnerPharmacyCtaCardHeader from './NoPartnerPharmacyCtaCardHeader';

export const noPartnerPharmacyCtaMessages = defineMessages({
  requestCallback: {
    id: 'requestCallback',
    defaultMessage: 'request a callback',
  },
  arrangeAppointment: {
    id: 'arrangeAppointment',
    defaultMessage: 'Arrange an appointment',
  },
  orderOnline: {
    id: 'orderOnline',
    defaultMessage: 'order online',
  },
  nearPharmacy: {
    id: 'nearPharmacy',
    defaultMessage: 'close by pharmacy',
  },
  sameDayDelivery: {
    id: 'sameDayDelivery',
    defaultMessage: 'same-day delivery',
  },
  allOnline: {
    id: 'allOnline',
    defaultMessage: 'order and pay online',
  },
  findNearbyPharmacy: {
    id: 'findNearbyPharmacy',
    defaultMessage: 'Find a pharmacy nearby now',
  },
  happyPill: {
    id: 'happyPill',
    defaultMessage: 'happy apoly-pill',
  },
});

const nearPharmacyAvailableBulletPoints = [
  <I18nMessage message={noPartnerPharmacyCtaMessages.nearPharmacy} />,
  <I18nMessage message={noPartnerPharmacyCtaMessages.sameDayDelivery} />,
  <I18nMessage message={noPartnerPharmacyCtaMessages.allOnline} />,
];

const contactOnlineBulletPoints = [
  <I18nMessage message={noPartnerPharmacyCtaMessages.requestCallback} />,
  <I18nMessage message={noPartnerPharmacyCtaMessages.orderOnline} />,
  <I18nMessage message={noPartnerPharmacyCtaMessages.arrangeAppointment} />,
];

const InnerNoPartnerPharmacyCta = props => (
  <React.Fragment>
    <LoadPharmaciesByZip
      zip={props.zip}
      setPharmacies={props.setSearchedPharmacies}
      setIsLoading={props.setIsLoading}
      onError={props.setError}
    />

    <LoadGermanCityPicturesLoadable
      zip={props.zip}
      setPictureUrl={props.setCityPictureUrl}
    />

    <Card style={{ minHeight: 400 }}>
      <NoPartnerPharmacyCtaCardHeader
        noPartnerPharmacy={props.noPartnerPharmacy}
        searchedPharmacies={props.searchedPharmacies}
      />

      <CardMedia image={props.cityPictureUrl} style={{ minHeight: 200 }} />

      <CardContent>
        {props.isLoading && (
          <ShowComponentWhileLoading isLoading={props.isLoading} />
        )}

        {props.error && <DefaultError error={props.error} />}

        <div className={pharmacyCtaGrid}>
          <Hidden only="md">
            <div className={gridArea('happyPill')}>
              <div style={flexAlignFlexStart}>
                <img
                  src={happyPill}
                  style={{
                    maxWidth: '100%',
                    margin: 'auto',
                    maxHeight: '100%',
                  }}
                  alt={props.intl.formatMessage(
                    noPartnerPharmacyCtaMessages.happyPill,
                    { brand: BRAND_NAME }
                  )}
                />
              </div>
            </div>
          </Hidden>
          <div className={gridArea('list')}>
            {props.searchedPharmacies &&
              props.searchedPharmacies.length > 0 && (
                <ListWithBulletPoints
                  listArray={nearPharmacyAvailableBulletPoints}
                  icon={faCheck}
                  iconFontSize="1.25rem"
                />
              )}

            {props.searchedPharmacies &&
              props.searchedPharmacies.length <= 0 && (
                <ListWithBulletPoints
                  listArray={contactOnlineBulletPoints}
                  icon={faCheck}
                  iconFontSize="1.25rem"
                />
              )}
          </div>
          <div className={gridArea('cta')}>
            <div style={flex}>
              <div style={{ marginLeft: 'auto' }}>
                {props.searchedPharmacies &&
                  props.searchedPharmacies.length > 0 && (
                    <ButtonWithIcon
                      component={Link}
                      to={choosePharmacyPath({
                        query: `${props.zip} ${props.city}`,
                      })}
                      iconRight
                      icon={faChevronDoubleRight}
                      variant="raised"
                      color="secondary"
                    >
                      <I18nMessage
                        message={
                          noPartnerPharmacyCtaMessages.findNearbyPharmacy
                        }
                      />
                    </ButtonWithIcon>
                  )}

                {props.searchedPharmacies &&
                  props.searchedPharmacies.length <= 0 && (
                    <ContactNoPartnerPharmacyButton
                      noPartnerPharmacy={props.noPartnerPharmacy}
                    />
                  )}
              </div>
            </div>
          </div>
        </div>
      </CardContent>
    </Card>
  </React.Fragment>
);

const enhance = compose(
  withState('searchedPharmacies', 'setSearchedPharmacies', null),
  withState('isLoading', 'setIsLoading', false),
  withState('error', 'setError', null),
  withState('cityPictureUrl', 'setCityPictureUrl', defaultPic),
  injectIntl
);

const NoPartnerPharmacyCta = enhance(InnerNoPartnerPharmacyCta);

NoPartnerPharmacyCta.propTypes = {
  noPartnerPharmacy: PropTypes.object.isRequired,
  zip: PropTypes.string.isRequired,
  city: PropTypes.string.isRequired,
};

NoPartnerPharmacyCta.defaultProps = {};

InnerNoPartnerPharmacyCta.propTypes = {
  searchedPharmacies: PropTypes.array,
  setSearchedPharmacies: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
  setIsLoading: PropTypes.func.isRequired,
  error: PropTypes.object,
  setError: PropTypes.func.isRequired,
  cityPictureUrl: PropTypes.string.isRequired,
  setCityPictureUrl: PropTypes.func.isRequired,
  ...NoPartnerPharmacyCta.propTypes,
};

InnerNoPartnerPharmacyCta.defaultProps = {
  searchedPharmacies: null,
  error: null,
};

export default NoPartnerPharmacyCta;
