import {
  faFacebookF,
  faGooglePlusG,
  faInstagram,
  faTwitter,
} from '@fortawesome/free-brands-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Grid, withStyles } from '@material-ui/core';
import PropTypes from 'prop-types';
import React from 'react';
import { flexAlignCenter } from '../../../constants/styles/commonClasses';
import FooterHeadline from './FooterHeadline';

const styles = {
  container: {
    display: 'flex',
    margin: 'auto',
  },
  button: {
    margin: '0px 8px',
    '@media(max-width: 600px)': {
      width: 48,
      height: 48,
    },
  },
};

const SocialMedia = ({ classes, title, colored }) => {
  const iconColor = colored ? 'primary' : 'default';
  return (
    <Grid container spacing={16}>
      {title && (
        <Grid item xs={12}>
          <FooterHeadline align="center">{title}</FooterHeadline>
        </Grid>
      )}
      <Grid item xs={12} style={flexAlignCenter}>
        <div className={classes.container}>
          <Button
            variant="fab"
            color={iconColor}
            aria-label="facebook"
            target="_blank"
            rel="noopener"
            href={'https://www.facebook.com/apoly.de/'}
            className={classes.button}
          >
            <FontAwesomeIcon icon={faFacebookF} />
          </Button>
          <Button
            variant="fab"
            color={iconColor}
            aria-label="twitter"
            target="_blank"
            rel="noopener"
            href={'https://twitter.com/ApolyDE'}
            className={classes.button}
          >
            <FontAwesomeIcon icon={faTwitter} />
          </Button>
          <Button
            variant="fab"
            color={iconColor}
            aria-label="google plus"
            target="_blank"
            rel="noopener"
            href={'https://plus.google.com/+ApolyDe'}
            className={classes.button}
          >
            <FontAwesomeIcon icon={faGooglePlusG} />
          </Button>
          <Button
            variant="fab"
            color={iconColor}
            aria-label="instagram"
            target="_blank"
            rel="noopener"
            href={'https://www.instagram.com/Apoly.de/'}
            className={classes.button}
          >
            <FontAwesomeIcon icon={faInstagram} />
          </Button>
        </div>
      </Grid>
    </Grid>
  );
};

SocialMedia.propTypes = {
  classes: PropTypes.object.isRequired,
  colored: PropTypes.bool,
  title: PropTypes.node,
};
SocialMedia.defaultProps = {
  colored: false,
  title: null,
};

export default withStyles(styles)(SocialMedia);
