import * as React from 'react';
import { FormattedMessage, MessageValue } from 'react-intl';

interface I18nMessageProps {
  message: FormattedMessage.MessageDescriptor;
  tagName?: string;
  values?: { [key: string]: MessageValue | JSX.Element };
}

export class I18nMessage extends React.PureComponent<I18nMessageProps> {
  render() {
    return (
      <FormattedMessage
        {...this.props.message}
        values={this.props.values}
        tagName={this.props.tagName}
      />
    );
  }
}
