import { Grid, Toolbar } from '@material-ui/core';
import { css } from 'emotion';
import React from 'react';
import { Link } from 'react-router-dom';
import SearchAutoSuggestComponent from '../../../../components/searchAutoSuggest/SearchAutoSuggestComponent';
import { flex, flexRight } from '../../../../constants/styles/commonClasses';
import { Pharmacy } from '../../../../dbTypes';
import { partnerPharmacyPath } from '../../../../routes/paths';
import { PharmacyTheme } from '../../../PharmacyThemeInterface';

const searchBar = css`
  width: 50%;
  @media (max-width: 600px) {
    width: 100%;
  }
`;

interface ToolBarMiddleProps {
  pharmacyTheme: PharmacyTheme;
  basePath: string;
  pharmacy: Pharmacy;
  clearSearchFilterOptions: () => void;
}

export const ToolBarMiddle: React.SFC<ToolBarMiddleProps> = props => (
  <Grid
    container
    justify="center"
    spacing={0}
    style={{ backgroundColor: '#fff' }}
  >
    <Grid item xs={12} lg={10}>
      <Toolbar>
        {props.pharmacyTheme.logo && (
          <Link
            style={{ maxWidth: '20%', margin: 8, textDecoration: 'none' }}
            component={Link}
            to={partnerPharmacyPath(props.basePath)}
          >
            <img src={props.pharmacyTheme.logo} />
          </Link>
        )}
        <div style={flex} />
        <div style={flexRight} className={searchBar}>
          <SearchAutoSuggestComponent
            pharmacy={props.pharmacy}
            onWillRedirect={props.clearSearchFilterOptions}
          />
        </div>
      </Toolbar>
    </Grid>
  </Grid>
);
