import PropTypes from 'prop-types';
/* eslint-disable no-shadow */
import React from 'react';
import { Route, withRouter } from 'react-router-dom';
import { LastLocationProvider } from 'react-router-last-location';
import { compose } from 'recompose';
import Route404Switch from '../containers/Route404Switch';
import { setPharmacyPath } from './paths';
import RoutesWithoutSetPharmacy from './RoutesWithoutSetPharmacy';
import RoutesWithSetPharmacy from './RoutesWithSetPharmacy';

const InnerAppRoutes = ({ location }) => (
  <LastLocationProvider>
    <Route404Switch>
      <Route
        path={setPharmacyPath()}
        location={location}
        component={RoutesWithSetPharmacy}
      />
      <Route
        path="/"
        location={location}
        component={RoutesWithoutSetPharmacy}
      />
    </Route404Switch>
  </LastLocationProvider>
);

const enhance = compose(withRouter);

const AppRoutes = enhance(InnerAppRoutes);

InnerAppRoutes.propTypes = {
  location: PropTypes.object.isRequired,
};

export default AppRoutes;
