import moment from 'moment-timezone/moment-timezone';
import timeZoneData from './assets/berlinTimeZoneData.json';

// the berlinTimeZoneData is compiled via the createTimeZoneData.js-node-script for 2015-2020,

const setupTimeZoneMomentData = () => {
  moment.tz.load(timeZoneData);
};

export default setupTimeZoneMomentData;
