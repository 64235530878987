import { logError } from '@apoly-42/apoly-utils';
import { parse } from 'qs';

export const parseSearchStringIfAvailable = locationSearch => {
  if (!locationSearch || locationSearch.length <= 1) {
    return null;
  }

  try {
    return parse(locationSearch.substr(1));
  } catch (ex) {
    logError(ex);
    return null;
  }
};
