import { Theme, withStyles } from '@material-ui/core';
import React from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick-theme.css';
import 'slick-carousel/slick/slick.css';

const startHeight = 400;

const styles = theme => ({
  container: {
    height: `${startHeight}px`,
    [`${theme.breakpoints.up('xs')}`]: {
      height: `${startHeight + 40}px`,
    },
    [theme.breakpoints.up('sm')]: {
      height: `${startHeight + 2 * 40}px`,
    },
    [theme.breakpoints.up('md')]: {
      height: `${startHeight + 3 * 40}px`,
    },
    [theme.breakpoints.up('lg')]: {
      height: `${startHeight + 4 * 40}px`,
    },
  },
  sliderContainer: {
    position: 'relative',
  },
  slide: {
    height: '100%',
  },
  slider: {
    position: 'absolute',
    height: 0,
    '&>*': {
      height: `${startHeight}px`,
      [`${theme.breakpoints.up('xs')}`]: {
        height: `${startHeight + 40}px`,
      },
      [theme.breakpoints.up('sm')]: {
        height: `${startHeight + 2 * 40}px`,
      },
      [theme.breakpoints.up('md')]: {
        height: `${startHeight + 3 * 40}px`,
      },
      [theme.breakpoints.up('lg')]: {
        height: `${startHeight + 4 * 40}px`,
      },
    },
  },
});

const settings = {
  centerMode: false,
  accessibility: true,
  slidesToShow: 1,
  draggable: true,
  infinite: true,
  autoplay: true,
  pauseOnHover: true,
  speed: 2000, // transition between images speed
  autoplaySpeed: 10000, // image duration
  arrows: false,
  fade: true,
};

const InnerPharmacySlider = ({
  children,
  classes,
  sliderImages,
  overlapContent,
}) => (
  <div className={overlapContent ? '' : classes.container}>
    <div className={classes.sliderContainer}>
      <Slider className={classes.slider} {...settings}>
        {sliderImages.map((img, index) => (
          <img src={img} className={classes.slide} key={index} />
        ))}
      </Slider>{' '}
    </div>
    <div>{children}</div>
  </div>
);

const PharmacySlider = withStyles(styles)(InnerPharmacySlider);

export default PharmacySlider;
