import PropTypes from 'prop-types';
import React from 'react';
import PharmacyDeliveryTimeSpan from './PharmacyDeliveryTimeSpan';

const NextCourierTimeSpan = props => (
  <PharmacyDeliveryTimeSpan
    courierTimeSpan={props.enhancedPharmacy.nextCourierTimeSpans[0]}
    deliveryFromMoment={
      props.enhancedPharmacy.nextCourierTimeSpans[0].deliveryFromMoment
    }
    deliveryToMoment={
      props.enhancedPharmacy.nextCourierTimeSpans[0].deliveryToMoment
    }
    absoluteDeliveryStartsAtMoment={
      props.enhancedPharmacy.nextCourierTimeSpans[0]
        .absoluteDeliveryStartsAtMoment
    }
  />
);

NextCourierTimeSpan.propTypes = {
  enhancedPharmacy: PropTypes.object.isRequired,
};

NextCourierTimeSpan.defaultProps = {};

export default NextCourierTimeSpan;
