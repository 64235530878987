/* eslint-disable */
import { createStore } from 'redux';
import rootReducer from '../../redux/reducer';

const getExtension = () =>
  process.env.NODE_ENV === 'development' &&
  window.__REDUX_DEVTOOLS_EXTENSION__ &&
  window.__REDUX_DEVTOOLS_EXTENSION__();

const createReduxStore = () => createStore(rootReducer, getExtension());

export default createReduxStore;
