import { Button, Hidden } from '@material-ui/core';
import PropTypes from 'prop-types';
import React from 'react';
import ReactPlaceholder from 'react-placeholder';
import { RoundShape, TextBlock } from 'react-placeholder/lib/placeholders';
import 'react-placeholder/lib/reactPlaceholder.css';
import { flex } from '../../../../../constants/styles/commonClasses';

const PlaceholderComponent = (
  <Button style={flex}>
    <RoundShape
      color="#E0E0E0"
      style={{ margin: 'auto 8px', width: 28, height: 18 }}
    />
    <Hidden smDown>
      <TextBlock rows={1} color="#E0E0E0" style={{ minWidth: 48 }} />
    </Hidden>
  </Button>
);

const ButtonWithIconLoadingPlaceholder = ({ ready, children }) => (
  <ReactPlaceholder
    ready={ready}
    showLoadingAnimation
    customPlaceholder={PlaceholderComponent}
  >
    {children || PlaceholderComponent}
  </ReactPlaceholder>
);

ButtonWithIconLoadingPlaceholder.propTypes = {
  children: PropTypes.node,
  ready: PropTypes.bool,
};

ButtonWithIconLoadingPlaceholder.defaultProps = {
  children: null,
  ready: false,
};

export default ButtonWithIconLoadingPlaceholder;
