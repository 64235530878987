import { I18nMessage } from '@apoly-42/apoly-components';
import { withHandler } from '@apoly-42/apoly-utils';
import {
  Dialog,
  DialogContent,
  DialogTitle,
  withStyles,
} from '@material-ui/core';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { commonMessages } from '../../../../constants/messages/commonMessages';
import { clearSearchFilterOptions } from '../../../../redux/medicSearchFilter/reducer';
import SearchAutoSuggestComponent from '../../../searchAutoSuggest/SearchAutoSuggestComponent';

const styles = {
  absoluteSubNavBar: {
    position: 'absolute',
    top: 112,
    margin: '16px 0px',
    width: '80%',
    overflowY: 'initial',
    maxWidth: '100%',
    '@media (max-width: 600px)': {
      width: '100%',
      margin: 0,
      top: 112,
      padding: 8,
    },
  },
  absolute: {
    position: 'absolute',
    top: 48,
    margin: '16px 0px',
    width: '80%',
    maxWidth: '100%',
    '@media (max-width: 600px)': {
      width: '100%',
      margin: 0,
      top: 56,
      padding: 8,
    },
    overflowY: 'initial',
  },
};

const InnerSearchBar = ({
  classes,
  pharmacy,
  closeSearchBar,
  open,
  ...props
}) => (
  <Dialog
    classes={{ paper: pharmacy ? classes.absoluteSubNavBar : classes.absolute }}
    open={open}
    onClose={closeSearchBar}
    aria-labelledby="responsive-dialog-title"
  >
    <DialogTitle id="responsive-dialog-title">
      <I18nMessage message={commonMessages.medicSearch} />
    </DialogTitle>
    <DialogContent style={{ overflowY: 'initial' }}>
      <SearchAutoSuggestComponent
        closeSearchBar={closeSearchBar}
        pharmacy={pharmacy}
        onWillRedirect={props.clearSearchFilterOptions}
      />
    </DialogContent>
  </Dialog>
);

const enhance = compose(
  connect(
    null,
    { clearSearchFilterOptions }
  ),
  withHandler('searchOnEnter', props => value =>
    props.onEnter && [props.onEnter(value), props.closeSearchBar()]
  ),
  withStyles(styles)
);

const SearchBar = enhance(InnerSearchBar);

SearchBar.propTypes = {
  open: PropTypes.bool.isRequired,
  pharmacy: PropTypes.object,
  closeSearchBar: PropTypes.func.isRequired,
  onEnter: PropTypes.func,
};

SearchBar.defaultProps = {
  pharmacy: null,
  onEnter: null,
};

InnerSearchBar.propTypes = {
  classes: PropTypes.object.isRequired,
  searchOnEnter: PropTypes.func.isRequired,
  ...SearchBar.propTypes,
};

InnerSearchBar.defaultProps = {
  ...SearchBar.defaultProps,
};

export default SearchBar;
