import { I18nMessage } from '@apoly-42/apoly-components';
import { Grid, Typography } from '@material-ui/core';
import React from 'react';
import { defineMessages } from 'react-intl';
import { BRAND_NAME } from '../../../environment';
import FooterHeadline from './FooterHeadline';

const messages = defineMessages({
  about: {
    id: 'about',
    defaultMessage: 'about {brand}',
  },
  orderMedicsAtPharmacy: {
    id: 'orderMedicsAtPharmacy',
    defaultMessage:
      'Order your prescriptions of medicines or your over the counter medicine at your local partner pharmacy anytime!',
  },
});

const AboutCompany = () => (
  <Grid container spacing={16}>
    <Grid item xs={12}>
      <FooterHeadline>
        <I18nMessage message={messages.about} values={{ brand: BRAND_NAME }} />
      </FooterHeadline>
    </Grid>
    <Grid item xs={12}>
      <Typography>
        <I18nMessage message={messages.orderMedicsAtPharmacy} />
        <sup>1,2,3</sup>
      </Typography>
    </Grid>
  </Grid>
);

export default AboutCompany;
