import { AppBar } from '@material-ui/core';
import React from 'react';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { selectUser } from '../../../../redux/auth/reducer';
import { clearSearchFilterOptions } from '../../../../redux/medicSearchFilter/reducer';
import {
  selectBasePath,
  selectPharmacy,
} from '../../../../redux/shopPharmacy/reducer';
import { ToolBarMiddle } from './ToolBarMiddle';
import { ToolBarTop } from './ToolBarTop';
import { ToolBarBottom } from './ToolBarBottom';
import firebase from 'firebase';
import { Pharmacy } from '../../../../dbTypes';
import { PharmacyTheme } from '../../../PharmacyThemeInterface';

interface InnerToolBarTopProps {
  user: firebase.User;
  pharmacy: Pharmacy;
  clearSearchFilterOptions: () => void;
  basePath: string;
}

interface ToolBarTopProps {
  pharmacyTheme: PharmacyTheme;
  logOut: () => void;
}

export const InnerLindenApothekeNavbar: React.SFC<
  ToolBarTopProps & InnerToolBarTopProps
> = ({
  user,
  pharmacyTheme,
  pharmacy,
  basePath,
  clearSearchFilterOptions,
  logOut,
}) => (
  <AppBar position="static">
    <ToolBarTop
      user={user}
      logOut={logOut}
      basePath={basePath}
      pharmacy={pharmacy}
    />
    <ToolBarMiddle
      pharmacyTheme={pharmacyTheme}
      basePath={basePath}
      pharmacy={pharmacy}
      clearSearchFilterOptions={clearSearchFilterOptions}
    />
    <ToolBarBottom
      pharmacy={pharmacy}
      basePath={basePath}
      pharmacyTheme={pharmacyTheme}
    />
  </AppBar>
);

const mapStateToProps = state => ({
  user: selectUser(state),
  pharmacy: selectPharmacy(state),
  basePath: selectBasePath(state),
});

const enhance = compose<
  InnerToolBarTopProps & ToolBarTopProps,
  ToolBarTopProps
>(
  connect(
    mapStateToProps,
    { clearSearchFilterOptions }
  )
);

export const LindenApothekeNavbar = enhance(InnerLindenApothekeNavbar);
