import { DefaultError } from '@apoly-42/material-ui';
import PropTypes from 'prop-types';
import * as React from 'react';
import { logErrorGracefully } from '../utilities/failGracefully';
import DefaultPageTemplate from './layout/DefaultPageTemplate';

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  componentDidCatch(error, info) {
    // Display fallback UI
    this.setState({ hasError: true });
    // You can also log the error to an error reporting service
    logErrorGracefully(error, { extra: info });
  }

  render() {
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return <DefaultError baseComponent={DefaultPageTemplate} />;
    }
    return this.props.children;
  }
}

ErrorBoundary.propTypes = {
  children: PropTypes.element.isRequired,
};

export default ErrorBoundary;
