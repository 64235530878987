import { createContainer, onMount, onWillUnmount } from '@apoly-42/apoly-utils';
import moment from 'moment-timezone/moment-timezone';
import { tap } from 'ramda';
import { connect } from 'react-redux';
import { withHandlers } from 'recompose';
import { getServerTime } from '../../../utilities/apis/apolyApi/apolyApi';
import { BERLIN_TIME_ZONE } from '../../../utilities/moment/momentConstants';
import { wrapHandlerWithGracefulLogging } from '../../../utilities/wrapHandlerWithGracefulLogging';
import {
  changeGermanServerTimeMoment,
  changeServerTime,
  changeServerTimeMoment,
  selectGermanServerTimeMoment,
  selectServerTime,
  selectServerTimeMoment,
} from '../serverTime';
import { setServerTimeError } from '../serverTimeLoadingState';

const mapStateToProps = state => ({
  serverTime: selectServerTime(state),
  serverTimeMoment: selectServerTimeMoment(state),
  germanServerTimeMoment: selectGermanServerTimeMoment(state),
});

const mapDispatchToProps = {
  changeServerTime,
  changeServerTimeMoment,
  changeGermanServerTimeMoment,
  setServerTimeError,
};

const SyncServerTime = createContainer(
  connect(
    mapStateToProps,
    mapDispatchToProps
  ),
  withHandlers({
    updateServerTime: props => () =>
      getServerTime()
        .then(response => response.data.serverTime)
        .then(tap(serverTime => props.changeServerTime(serverTime)))
        .then(
          tap(serverTime => props.changeServerTimeMoment(moment(serverTime)))
        )
        .then(
          tap(serverTime =>
            props.changeGermanServerTimeMoment(
              moment.tz(serverTime, BERLIN_TIME_ZONE)
            )
          )
        )
        .then(tap(() => props.setServerTimeError(null)))
        .catch(wrapHandlerWithGracefulLogging(props.setServerTimeError)),
  }),
  withHandlers(() => {
    let intervalId;

    return {
      startSyncServerTimeInterval: props => () => {
        props.updateServerTime();
        intervalId = window.setInterval(props.updateServerTime, 60000); // reload time every 60 seconds
      },
      clearSyncServerTimeInterval: () => () => window.clearInterval(intervalId),
    };
  }),
  onMount(props => props.startSyncServerTimeInterval()),
  onWillUnmount(props => props.clearSyncServerTimeInterval())
);

export default SyncServerTime;
