import { I18nMessage } from '@apoly-42/apoly-components';
import { faCaretRight } from '@fortawesome/pro-solid-svg-icons';
import PropTypes from 'prop-types';
import React from 'react';
import { commonMessages } from '../../../../../constants/messages/commonMessages';
import ListItemButtonWithIcon from '../ListItemButtonWithIcon';

const CloseDrawerButton = ({ handleDrawer }) => (
  <ListItemButtonWithIcon
    text={<I18nMessage message={commonMessages.closeDrawer} />}
    icon={faCaretRight}
    onClickEvent={handleDrawer}
  />
);

CloseDrawerButton.propTypes = {
  handleDrawer: PropTypes.func.isRequired,
};

export default CloseDrawerButton;
