import { FormattedEuro, I18nMessage } from '@apoly-42/apoly-components';
import { CustomTooltip } from '@apoly-42/material-ui';
import { faShoppingCart } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, withStyles } from '@material-ui/core';
import cn from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import Link from 'react-router-dom/Link';
import { compose } from 'recompose';
import { commonMessages } from '../../constants/messages/commonMessages';
import {
  buttonXs,
  verticalCenteredInFlex,
} from '../../constants/styles/commonClasses';
import { selectBasePath } from '../../redux/shopPharmacy/reducer';
import {
  getShoppingCartLength,
  getShoppingCartTotalPrice,
} from '../../redux/shoppingCart/selectors';
import { shoppingCartPath } from '../../routes/paths';

const styles = {
  verticalCenteredInFlexResponsive: {
    ...verticalCenteredInFlex,
    '@media(max-width: 350px)': {
      margin: 'auto 4px',
    },
  },
  buttonXs,
  badge: {
    top: -10,
    right: -4,
  },
};

const ShoppingCartButtonWithAdditionalTextView = ({
  classes,
  basePath,
  shoppingCartPrice,
  shoppingCartLength,
  intl: { formatMessage },
}) => (
  <CustomTooltip
    id="shoppingCart"
    title={
      <span>
        {formatMessage(commonMessages.totalAmount)}
        :&nbsp;
        <FormattedEuro value={Number(shoppingCartPrice)} />
      </span>
    }
    placement="bottom"
  >
    <Button
      className={cn(classes.verticalCenteredInFlexResponsive, classes.buttonXs)}
      variant={shoppingCartLength > 0 ? 'raised' : 'flat'}
      color={shoppingCartLength > 0 ? 'secondary' : 'inherit'}
      component={Link}
      aria-label={formatMessage(commonMessages.shoppingCart)}
      to={shoppingCartPath(basePath)}
    >
      <div style={{ display: 'flex', flexDirection: 'column' }}>
        <I18nMessage message={commonMessages.shoppingCart} />
        <I18nMessage
          message={commonMessages.articlesWithPrice}
          values={{
            articlesCount: shoppingCartLength || 0,
            price: <FormattedEuro value={Number(shoppingCartPrice)} />,
          }}
        />
      </div>
      <FontAwesomeIcon
        style={{ margin: 'auto 8px', fontSize: 32 }}
        icon={faShoppingCart}
      />
    </Button>
  </CustomTooltip>
);

ShoppingCartButtonWithAdditionalTextView.propTypes = {
  classes: PropTypes.object.isRequired,
  intl: PropTypes.object.isRequired,
  basePath: PropTypes.string.isRequired,
  shoppingCartLength: PropTypes.number,
  shoppingCartPrice: PropTypes.string,
};
ShoppingCartButtonWithAdditionalTextView.defaultProps = {
  shoppingCartLength: null,
  shoppingCartPrice: '0.00',
};

const mapStateToProps = state => ({
  shoppingCartLength: getShoppingCartLength(state),
  shoppingCartPrice: getShoppingCartTotalPrice(state),
  basePath: selectBasePath(state),
});

const enhance = compose(
  connect(mapStateToProps),
  injectIntl,
  withStyles(styles)
);

export default enhance(ShoppingCartButtonWithAdditionalTextView);
