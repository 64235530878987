import { withToggleState } from '@apoly-42/apoly-components';
import { Fade, Snackbar, withStyles } from '@material-ui/core';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { selectUser } from '../../../../redux/auth/reducer';
import AvatarForTabbar from './AvatarForTabbar';
import UserLogOut from './UserLogOut';

const styles = {
  whiteBg: {
    backgroundColor: '#fff!important',
    '@media(max-width: 600px)': {
      padding: 8,
    },
  },
  anchor: {
    position: 'absolute',
    top: '64px',
    maxWidth: '320px',
    left: 'auto',
    right: '8px',
    '@media(max-width: 600px)': {
      maxWidth: '100%',
      left: 'auto',
      right: '0px',
    },
  },
};

const InnerUserMenuForTabbar = ({
  open,
  toggleOpen,
  classes,
  user,
  logOut,
}) => (
  <div style={{ margin: '0px 16px' }}>
    <div style={{ width: 40 }}>
      <AvatarForTabbar
        defaultUserIconSizeTabBar={40}
        open={open}
        handleClick={toggleOpen}
        user={user}
      />
      <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        open={open}
        onClose={toggleOpen}
        TransitionComponent={Fade}
        classes={{
          anchorOriginTopRight: classes.anchor,
          root: classes.root,
        }}
        ContentProps={{
          'aria-describedby': 'user-id',
          classes: { root: classes.whiteBg },
        }}
        message={
          <UserLogOut
            open={open}
            handleClick={toggleOpen}
            user={user}
            logOut={logOut}
            id="user-id"
          />
        }
      />
    </div>
  </div>
);

const mapStateToProps = state => ({
  user: selectUser(state),
});

const enhance = compose(
  withStyles(styles),
  withToggleState('open', 'toggleOpen', false),
  connect(mapStateToProps)
);

const UserMenuForTabbar = enhance(InnerUserMenuForTabbar);

UserMenuForTabbar.propTypes = {
  logOut: PropTypes.func.isRequired,
};

InnerUserMenuForTabbar.propTypes = {
  user: PropTypes.object,
  open: PropTypes.bool.isRequired,
  toggleOpen: PropTypes.func.isRequired,
  classes: PropTypes.object.isRequired,
  ...UserMenuForTabbar.propTypes,
};

InnerUserMenuForTabbar.defaultProps = {
  user: null,
};

export default UserMenuForTabbar;
