/* eslint-disable no-shadow */
import React from 'react';
import { Route, withRouter } from 'react-router-dom';
import { compose } from 'recompose';
import LoadPharmacyShopLoadable from '../containers/loadingContainer/LoadPharmacyShopLoadable';
import { pharmacyShopRoute } from './paths';

const renderPharmacyShopRoute = locationState => (
  <Route
    path={pharmacyShopRoute(':pharmacyUrlCode', ':postal')}
    render={({ match, location }) => (
      <LoadPharmacyShopLoadable
        key={match.params.pharmacyUrlCode + match.params.postal}
        pharmacyUrlCode={match.params.pharmacyUrlCode}
        postal={match.params.postal}
        pharmacyId={location.state && location.state.pharmacyId}
        location={{ ...location, state: locationState }}
      />
    )}
  />
);

const InnerRoutesWithSetPharmacy = ({ location }) =>
  renderPharmacyShopRoute(location.state);

const enhance = compose(withRouter);

const RoutesWithSetPharmacy = enhance(InnerRoutesWithSetPharmacy);

export default RoutesWithSetPharmacy;
