import { I18nMessage } from '@apoly-42/apoly-components';
import { ButtonWithIcon } from '@apoly-42/material-ui';
import {
  faCamera,
  faChevronDoubleRight,
  faHospital,
} from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Grid, Toolbar, Typography } from '@material-ui/core';
import { css } from 'emotion';
import React from 'react';
import { Link } from 'react-router-dom';
import { commonMessages } from '../../../../constants/messages/commonMessages';
import { flex } from '../../../../constants/styles/commonClasses';
import {
  partnerPharmacyPath,
  submitPrescriptionPath,
} from '../../../../routes/paths';
import { Pharmacy } from '../../../../dbTypes';
import { defineMessages } from 'react-intl';
import { PharmacyTheme } from '../../../PharmacyThemeInterface';

const subNavBarHeight = 32;

const subnavBar = css`
  height: ${subNavBarHeight}px;
  min-height: ${subNavBarHeight}px!important;
`;

const boarderRight = css`
  height: ${subNavBarHeight}px;
  border-right: 1px solid;
  border-color: inherit;
`;

const boarderLeft = css`
  height: ${subNavBarHeight}px;
  border-left: 1px solid;
  border-color: inherit;
`;

const boarderWithFlex = (right: boolean, left: boolean) => css`
  ${right && boarderRight};
  ${left && boarderLeft};
  display: flex;
`;

const messages = defineMessages({
  aboutPharmacy: {
    id: 'aboutPharmacy',
    defaultMessage: 'about {pharmacy}',
  },
});

interface ToolBarBottomProps {
  basePath: string;
  pharmacy: Pharmacy;
  pharmacyTheme: PharmacyTheme;
}

export const ToolBarBottom: React.SFC<ToolBarBottomProps> = ({
  basePath,
  pharmacyTheme,
  pharmacy,
}) => (
  <Grid
    container
    justify="center"
    spacing={0}
    style={{
      color: '#fff',
      backgroundColor: pharmacyTheme.palette.secondary.main,
    }}
  >
    <Grid item xs={12} lg={10}>
      <Toolbar className={subnavBar}>
        <div style={{ display: 'flex', color: 'inherit', flex: 1 }}>
          <div className={boarderWithFlex(true, false)}>
            <div style={{ margin: 'auto 8px' }}>
              <FontAwesomeIcon icon={faHospital} />
            </div>
          </div>
          <div className={boarderWithFlex(true, false)}>
            <div style={{ margin: 'auto 8px' }}>
              <Typography
                color="inherit"
                style={{ textDecoration: 'none' }}
                component={Link}
                to={partnerPharmacyPath(basePath)}
              >
                <I18nMessage
                  message={messages.aboutPharmacy}
                  values={{ pharmacy: pharmacy.name }}
                />
              </Typography>
            </div>
          </div>
        </div>
        <div style={flex} />
        <div className={boarderWithFlex(true, true)}>
          <Typography
            color="inherit"
            style={{ textDecoration: 'none', margin: 'auto 8px' }}
            component={Link}
            to={submitPrescriptionPath(basePath)}
          >
            <span style={{ marginRight: 8 }}>
              <FontAwesomeIcon icon={faCamera} />
            </span>
            <I18nMessage message={commonMessages.submitPrescription} />
            &nbsp;
            <FontAwesomeIcon icon={faChevronDoubleRight} />
          </Typography>
        </div>
      </Toolbar>
    </Grid>
  </Grid>
);
