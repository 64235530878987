import {
  createContainer,
  is404,
  loadFromProps,
  onMountOrUpdate,
  withHandler,
} from '@apoly-42/apoly-utils';
import PropTypes from 'prop-types';
import { pipe, prop, tap, unless } from 'ramda';
import { loadPageCategoryFromUrlCode } from '../utilities/apis/apolyApi/apolyApi';
import { logErrorGracefully } from '../utilities/failGracefully';

const loadPageCategoryFromProps = props =>
  loadPageCategoryFromUrlCode(props.urlCode).then(tap(props.setCategory));

const LoadPageCategoryFromUrlCode = createContainer(
  withHandler('handleError', props =>
    pipe(
      tap(unless(is404, logErrorGracefully)),
      props.setError
    )
  ),
  onMountOrUpdate(
    ['urlCode'],
    loadFromProps(
      prop('setIsLoading'),
      prop('handleError'),
      loadPageCategoryFromProps
    )
  )
);

LoadPageCategoryFromUrlCode.propTypes = {
  urlCode: PropTypes.string.isRequired,
  setCategory: PropTypes.func.isRequired,
  setIsLoading: PropTypes.func.isRequired,
  setError: PropTypes.func.isRequired,
};

export default LoadPageCategoryFromUrlCode;
