import { IconWithTextInGrid } from '@apoly-42/material-ui';
import { faHospital, faUserMd } from '@fortawesome/pro-solid-svg-icons';
import { Grid } from '@material-ui/core';
import PropTypes from 'prop-types';
import React from 'react';
import { defineMessages, injectIntl } from 'react-intl';
import { compose } from 'recompose';
import { marginAuto } from '../../../../constants/styles/commonClasses';

export const pharmacyMessages = defineMessages({
  owner: {
    id: 'owner',
    defaultMessage: 'owner',
  },
});

const Pharmacy = ({ partnerPharmacy, intl: { formatMessage } }) => (
  <Grid container spacing={16} style={marginAuto}>
    <Grid item xs={12}>
      <IconWithTextInGrid icon={faHospital} text={partnerPharmacy.name} />
    </Grid>
    <Grid item xs={12}>
      <IconWithTextInGrid
        icon={faUserMd}
        text={`${formatMessage(pharmacyMessages.owner)}: ${
          partnerPharmacy.owner
        }`}
      />
    </Grid>
  </Grid>
);

Pharmacy.propTypes = {
  partnerPharmacy: PropTypes.object.isRequired,
  intl: PropTypes.object.isRequired,
};

const enhance = compose(injectIntl);

export default enhance(Pharmacy);
