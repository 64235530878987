import { equals } from 'ramda';
import * as React from 'react';
import { Pharmacy } from '../dbTypes';
import { cond } from '../utilities/fn-utils';
import { appPalette, pharmacyDefaults } from './apolyDefaults';
import { secTestCustomisations } from './pharmacy-customisations/sec-customisation';
import { testCustomisations } from './pharmacy-customisations/test-customisations';
import { thirdTestCustomisations } from './pharmacy-customisations/third-customisation';
import { PharmacyTheme } from './PharmacyThemeInterface';

const urlCodeToCustomisations = cond<string, Partial<PharmacyTheme>>([
  [equals(process.env.CUSTOM_PHARMACIES_TEST), () => testCustomisations],
  [equals(process.env.CUSTOM_PHARMACIES_TEST_2), () => secTestCustomisations],
  [equals(process.env.CUSTOM_PHARMACIES_TEST_3), () => thirdTestCustomisations],
]);

type Obj = { [key: string]: any };

function withoutFalsies<T extends Obj>(obj: T): Partial<T> {
  const res = {} as T;

  for (const key in obj) {
    if (obj[key]) {
      res[key] = obj[key];
    }
  }

  return res;
}

const paletteFromPharmacy = (pharmacy: Pharmacy) => {
  return Object.assign(
    {},
    appPalette,
    withoutFalsies({
      primary: pharmacy.color_primary
        ? { main: pharmacy.color_primary }
        : undefined,
      secondary: pharmacy.color_secondary
        ? { main: pharmacy.color_secondary }
        : undefined,
    })
  );
};

const getPharmacyThemeValuesFromDb = (
  pharmacy: Pharmacy
): Partial<PharmacyTheme> => ({
  palette: paletteFromPharmacy(pharmacy),
});

export function getPharmacyTheme(pharmacy: Pharmacy) {
  return Object.assign(
    {},
    pharmacyDefaults,
    getPharmacyThemeValuesFromDb(pharmacy),
    urlCodeToCustomisations(pharmacy.urlCode)
  );
}

const { Provider, Consumer } = React.createContext<PharmacyTheme>(
  pharmacyDefaults
);

export const PharmacyThemeContext = Consumer;
export const PharmacyThemeProvider = Provider;
