import { I18nMessage } from '@apoly-42/apoly-components';
import { Grid, withStyles } from '@material-ui/core';
import PropTypes from 'prop-types';
import { cond, equals } from 'ramda';
import React from 'react';
import { defineMessages } from 'react-intl';
import {
  blackWhiteFilter,
  flexAlignFlexStart,
} from '../../../constants/styles/commonClasses';
import { BRAND_NAME } from '../../../environment';
import cash from './assets/bar-w.png';
import mastercard from './assets/mastercard-w.png';
import paypal from './assets/paypal-w.png';
import sepa from './assets/sepa-w.png';
import sofort from './assets/sofort-ueberweisung-w.png';
import visa from './assets/visa-w.png';
import FooterHeadline from './FooterHeadline';

const getPaymentMethodImageForFooter = cond([
  [equals('cash'), () => cash],
  [equals('sofort'), () => sofort],
  [equals('visa'), () => visa],
  [equals('sepa'), () => sepa],
  [equals('mastercard'), () => mastercard],
  [equals('paypal'), () => paypal],
  [() => true, () => ''],
]);

const styles = {
  image: {
    maxWidth: '100%',
    margin: 'auto',
    ...blackWhiteFilter,
  },
};

const messages = defineMessages({
  possiblePaymentMethods: {
    id: 'possiblePaymentMethods',
    defaultMessage: 'possible payment Methods at {brand}',
  },
});

const possiblePaymentMethods = [
  'cash',
  'sofort',
  'visa',
  'sepa',
  'mastercard',
  'paypal',
];

const LawAgreements = ({ classes }) => (
  <Grid container spacing={16}>
    <Grid item xs={12}>
      <FooterHeadline>
        <I18nMessage
          message={messages.possiblePaymentMethods}
          values={{ brand: BRAND_NAME }}
        />
      </FooterHeadline>
    </Grid>
    <Grid item xs={12}>
      <Grid container spacing={16}>
        {possiblePaymentMethods &&
          possiblePaymentMethods.map(paymentMethod => (
            <Grid item xs={3} key={paymentMethod} style={flexAlignFlexStart}>
              <img
                className={classes.image}
                alt={paymentMethod}
                src={getPaymentMethodImageForFooter(paymentMethod)}
              />
            </Grid>
          ))}
      </Grid>
    </Grid>
  </Grid>
);

LawAgreements.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(LawAgreements);
