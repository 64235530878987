import PropTypes from 'prop-types';
import React from 'react';
import { defineMessages } from 'react-intl';
import { BRAND_NAME } from '../../environment';
import HelmetComponentWithMessage from '../helmet/HelmetComponentWithMessage';
import Footer from './footer/Footer';
import NavBar from './navBar/navbar/NavBar';

const messages = defineMessages({
  defaultPageTitle: {
    id: 'defaultPageTitle',
    defaultMessage: '{brand} | digital healthcare',
  },
});

const DefaultPageTemplate = props => (
  <React.Fragment>
    <HelmetComponentWithMessage
      pageTitle={messages.defaultPageTitle}
      values={{ brand: BRAND_NAME }}
    />
    <NavBar />

    {props.children}

    <Footer />
  </React.Fragment>
);

DefaultPageTemplate.propTypes = {
  children: PropTypes.node,
};

DefaultPageTemplate.defaultProps = {
  children: null,
};

export default DefaultPageTemplate;
