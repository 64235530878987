import { Headline } from '@apoly-42/material-ui';
import { Grid, withStyles } from '@material-ui/core';
import PropTypes from 'prop-types';
import React from 'react';
import { footerHeadline } from '../../../constants/styles/commonClasses';

const styles = {
  footerHeadline,
};

const FooterHeadline = ({ children, classes, align }) => (
  <Grid item xs={12}>
    <Headline
      className={classes.footerHeadline}
      headlineType="h3"
      align={align}
    >
      {children}
    </Headline>
  </Grid>
);

FooterHeadline.propTypes = {
  children: PropTypes.node.isRequired,
  classes: PropTypes.object.isRequired,
  align: PropTypes.string,
};

FooterHeadline.defaultProps = {
  align: 'left',
};

export default withStyles(styles)(FooterHeadline);
