import { I18nMessage } from '@apoly-42/apoly-components';
import { ButtonWithIcon, CustomTooltip } from '@apoly-42/material-ui';
import { faTruck } from '@fortawesome/pro-solid-svg-icons';
import { Toolbar, withStyles } from '@material-ui/core';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { commonMessages } from '../../../../constants/messages/commonMessages';
import { flex } from '../../../../constants/styles/commonClasses';
import { selectBasePath } from '../../../../redux/shopPharmacy/reducer';
import PharmacyInfoSubNavBar from './subNavBarComponents/PharmacyInfoSubNavBar';

const styles = {
  buttonXsTooltip: {
    margin: 'auto 8px',
    '@media (max-width: 600px)': {
      minWidth: 0,
    },
    cursor: 'default',
  },
  iconsRight: {
    display: 'flex',
    marginLeft: 'auto',
  },
};

const InnerSubNavBarPostal = ({ classes }) => (
  <Toolbar style={flex}>
    <PharmacyInfoSubNavBar />
    <div className={classes.iconsRight}>
      <CustomTooltip
        id="delivery"
        title={<I18nMessage message={commonMessages.postalDeliveryGermany} />}
        placement="bottom"
      >
        <ButtonWithIcon
          icon={faTruck}
          disableRipple
          className={classes.buttonXsTooltip}
          color="inherit"
        >
          <I18nMessage message={commonMessages.deliveryInWholeGermany} />
        </ButtonWithIcon>
      </CustomTooltip>
    </div>
  </Toolbar>
);

const mapStateToProps = state => ({
  basePath: selectBasePath(state),
});

const enhance = compose(
  connect(mapStateToProps),
  withStyles(styles)
);

const SubNavBarPostal = enhance(InnerSubNavBarPostal);

SubNavBarPostal.propTypes = {
  pharmacy: PropTypes.object.isRequired,
};

InnerSubNavBarPostal.propTypes = {
  classes: PropTypes.object.isRequired,
  basePath: PropTypes.string.isRequired,
  ...SubNavBarPostal.propTypes,
};

export default SubNavBarPostal;
