/* tslint:disable:ordered-imports */
import './utilities/bootstrap/polyfills';
import { loadCSS } from 'fg-loadcss';
import * as React from 'react';
import * as ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import App from './components/App';
import { registerServiceWorker } from './registerServiceWorker';
import createReduxStore from './utilities/bootstrap/createReduxStore';
import loadInitTagManager from './utilities/bootstrap/loadInitTagManager';
import setupApolyApiClient from './utilities/bootstrap/setupApolyApiClient';
import setupFirebase from './utilities/bootstrap/setupFirebase';
import setupRemoteErrorLogging from './utilities/bootstrap/setupRemoteErrorLogging';
import setupTimeZoneMomentData from './utilities/bootstrap/setupTimeZoneMomentData';
import { initHotjarFallback } from './utilities/hotjarWrapper';

declare global {
  interface Window {
    dataLayer: any[];
  }
}

// init dataLayer for googleTageManager, the tagManager will included via the cookieConsentScript
window.dataLayer = [];

initHotjarFallback();
setupRemoteErrorLogging();
setupApolyApiClient();
setupTimeZoneMomentData();
setupFirebase();

const store = createReduxStore();

loadInitTagManager();

const Bootstrapped = () => (
  <Provider store={store}>
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </Provider>
);

ReactDOM.render(<Bootstrapped />, document.getElementById('root'));

loadCSS(
  new URL('https://fonts.googleapis.com/css?family=Lora|Roboto:300,400,500')
);

registerServiceWorker();
