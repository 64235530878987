import { createAction, createReducer } from '@apoly-42/apoly-utils';
import { append, compose, contains, ifElse, prop, without } from 'ramda';
import { loadSearchFilterFromSessionStorage } from '../../components/pages/medicSearch/SyncFilterWithSessionStorage';
import { selectAppStateRoot } from '../selectors';

export const selectMedicSearchFilterState = compose(
  prop('medicSearchFilter'),
  selectAppStateRoot
);

export const setAgentsFilter = createAction('setAgentsFilter');
export const setBodyFlagsFilter = createAction('bodyFlags');
export const setDosageFormsFilter = createAction('setDosageFormsFilter');
export const setDosageFormGroupsFilter = createAction(
  'setDosageFormGroupsFilter'
);
export const setMedicineFlagsFilter = createAction('setMedicineFlagsFilter');
export const setProducersFilter = createAction('setProducersFilter');
export const setProducerGroupsFilter = createAction('setProducerGroupsFilter');
export const setSymptomsFilter = createAction('setSymptomsFilter');
export const setBodyRegionsFilter = createAction('setBodyRegionsFilter');

export const clearSearchFilterOptions = createAction(
  'clearSearchFilterOptions'
);

export const selectAgentsFilter = compose(
  prop('agentIds'),
  selectMedicSearchFilterState
);
export const selectBodyFlagsFilter = compose(
  prop('bodyFlags'),
  selectMedicSearchFilterState
);
export const selectDosageFormsFilter = compose(
  prop('dosageFormIds'),
  selectMedicSearchFilterState
);
export const selectDosageFormGroupsFilter = compose(
  prop('dosageFormGroupIds'),
  selectMedicSearchFilterState
);
export const selectMedicineFlagsFilter = compose(
  prop('medicineFlags'),
  selectMedicSearchFilterState
);
export const selectProducersFilter = compose(
  prop('producerIds'),
  selectMedicSearchFilterState
);
export const selectProducerGroupsFilter = compose(
  prop('producerGroupIds'),
  selectMedicSearchFilterState
);
export const selectSymptomsFilter = compose(
  prop('symptomIds'),
  selectMedicSearchFilterState
);
export const selectBodyRegionsFilter = compose(
  prop('bodyRegionIds'),
  selectMedicSearchFilterState
);

const removeItem = (item, array) => without([item], array);

const handleItem = ifElse(contains, removeItem, append);

export const searchFilterKeys = [
  'agentIds',
  'dosageFormIds',
  'medicineFlags',
  'producerIds',
  'symptomIds',
  'bodyFlags',
  'bodyRegionIds',
  'dosageFormGroupIds',
  'producerGroupIds',
];

const searchFilterDefault = {
  agentIds: [],
  dosageFormIds: [],
  medicineFlags: [],
  bodyFlags: [],
  producerIds: [],
  symptomIds: [],
  bodyRegionIds: [],
  dosageFormGroupIds: [],
  producerGroupIds: [],
};

const reducer = createReducer(
  { ...searchFilterDefault, ...loadSearchFilterFromSessionStorage() },
  {
    [setAgentsFilter]: (state, { payload }) => ({
      ...state,
      agentIds: handleItem(payload, state.agentIds),
    }),
    [setBodyFlagsFilter]: (state, { payload }) => ({
      ...state,
      bodyFlags: handleItem(payload, state.bodyFlags),
    }),
    [setDosageFormsFilter]: (state, { payload }) => ({
      ...state,
      dosageFormIds: handleItem(payload, state.dosageFormIds),
    }),
    [setDosageFormGroupsFilter]: (state, { payload }) => ({
      ...state,
      dosageFormGroupIds: handleItem(payload, state.dosageFormGroupIds),
    }),
    [setMedicineFlagsFilter]: (state, { payload }) => ({
      ...state,
      medicineFlags: handleItem(payload, state.medicineFlags),
    }),
    [setProducersFilter]: (state, { payload }) => ({
      ...state,
      producerIds: handleItem(payload, state.producerIds),
    }),
    [setProducerGroupsFilter]: (state, { payload }) => ({
      ...state,
      producerGroupIds: handleItem(payload, state.producerGroupIds),
    }),
    [setSymptomsFilter]: (state, { payload }) => ({
      ...state,
      symptomIds: handleItem(payload, state.symptomIds),
    }),
    [setBodyRegionsFilter]: (state, { payload }) => ({
      ...state,
      bodyRegionIds: handleItem(payload, state.bodyRegionIds),
    }),
    [clearSearchFilterOptions]: () => searchFilterDefault,
  }
);

export default reducer;
