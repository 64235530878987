import { withPropOnChange } from '@apoly-42/apoly-utils';
import { createMuiTheme, MuiThemeProvider } from '@material-ui/core';
import PropTypes from 'prop-types';
import { mergeDeepLeft } from 'ramda';
import * as React from 'react';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { selectLocale } from '../../redux/language/reducer';

export const createAppMuiTheme = themeOptions => {
  const vals = mergeDeepLeft(themeOptions || {}, {
    palette: {
      primary: {
        main: '#45919b',
      },
      secondary: {
        main: '#ff6f00',
      },
    },
    body1: {
      margin: '0px;',
    },
  });

  return createMuiTheme(vals);
};

const InnerAppMuiThemeProvider = ({ appTheme, children }) => (
  <MuiThemeProvider theme={appTheme}>{children}</MuiThemeProvider>
);

const mapStateToProps = state => ({
  locale: selectLocale(state),
});

const themeFromProps = props => {
  return createAppMuiTheme({
    direction: props.locale === 'ar' ? 'rtl' : 'ltr',
    palette: props.palette || {},
  });
};

const enhance = compose(
  connect(mapStateToProps),
  withPropOnChange(['locale', 'palette'], 'appTheme', themeFromProps)
);

const AppMuiThemeProvider = enhance(InnerAppMuiThemeProvider);

AppMuiThemeProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

InnerAppMuiThemeProvider.propTypes = {
  locale: PropTypes.string.isRequired,
  appTheme: PropTypes.object.isRequired,
  ...AppMuiThemeProvider.propTypes,
};

export default AppMuiThemeProvider;
