import { OnDidMountOrUpdate } from '@apoly-42/apoly-utils';
import { DefaultLoadable } from '@apoly-42/material-ui';
import PropTypes from 'prop-types';
import { always, cond, equals, T } from 'ramda';
import React from 'react';

export const EnLoadable = DefaultLoadable({
  loader: () => import('./En'),
});

export const DeLoadable = DefaultLoadable({
  loader: () => import('./De'),
});

export const TrLoadable = DefaultLoadable({
  loader: () => import('./Tr'),
});

export const RuLoadable = DefaultLoadable({
  loader: () => import('./Ru'),
});

export const ArLoadable = DefaultLoadable({
  loader: () => import('./Ar'),
});

const getAsyncLanguageLoadable = cond([
  [equals('de'), always(DeLoadable)],
  [equals('ar'), always(ArLoadable)],
  [equals('ru'), always(RuLoadable)],
  [equals('tr'), always(TrLoadable)],
  [lang => !lang, always(DeLoadable)],
  [T, always(EnLoadable)],
]);

// eslint-disable-next-line no-return-assign
const setBodyTextDirection = textDirection =>
  (document.body.dir = textDirection);

const AsyncLanguageLoadable = props => {
  const Component = getAsyncLanguageLoadable(props.locale);
  return (
    <React.Fragment>
      <OnDidMountOrUpdate
        prop={props.locale}
        handler={() =>
          props.locale === 'ar'
            ? setBodyTextDirection('rtl')
            : setBodyTextDirection('ltr')
        }
      />

      <Component {...props} />
    </React.Fragment>
  );
};

AsyncLanguageLoadable.propTypes = {
  locale: PropTypes.string.isRequired,
};

export default AsyncLanguageLoadable;
