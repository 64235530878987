import { faPhone, faUserMd } from '@fortawesome/pro-solid-svg-icons';
import { Hidden, Typography, withStyles } from '@material-ui/core';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import Link from 'react-router-dom/Link';
import { compose } from 'recompose';
import { flex } from '../../../../../constants/styles/commonClasses';
import { PharmacyThemeContext } from '../../../../../pharmacy-theme/pharmacyTheme';
import {
  selectBasePath,
  selectPharmacy,
} from '../../../../../redux/shopPharmacy/reducer';
import { getPharmacyPic } from '../../../../pages/choosePharmacy/expansionPanels/PharmacyExpansionPanel';
import PharmacyLogoDefault from '../../../../pharmacyLogoDefault/PharmacyLogoDefault';
import TextWithIcon from './TextWithIcon';

const styles = {
  link: {
    color: '#fff',
    textDecoration: 'none',
  },
  divClass: {
    marginRight: 24,
    color: '#fff',
  },
};

const InnerPharmacyInfoSubNavBar = ({ classes, pharmacy, basePath }) => (
  <div style={{ display: 'flex', flex: 1 }}>
    <div className={classes.divClass}>
      <Link to={basePath} className={classes.link}>
        {pharmacy.logoUrl ? (
          <img
            style={{ margin: 'auto', maxHeight: 58, maxWidth: '100%' }}
            src={pharmacy.logoUrl}
            alt={pharmacy.name}
          />
        ) : (
          <div style={{ display: 'flex' }}>
            <div style={{ margin: 'auto', marginRight: 16 }}>
              <PharmacyLogoDefault />
            </div>
            <Typography style={{ margin: 'auto' }} color="inherit">
              {pharmacy.name}
            </Typography>
          </div>
        )}
      </Link>
    </div>
    <Hidden mdDown>
      <div className={classes.divClass}>
        <TextWithIcon text={pharmacy.owner} icon={faUserMd} />
      </div>
    </Hidden>
    <Hidden mdDown>
      <div className={classes.divClass}>
        <TextWithIcon text={pharmacy.phone} icon={faPhone} />
      </div>
    </Hidden>
  </div>
);

const mapStateToProps = state => ({
  pharmacy: selectPharmacy(state),
  basePath: selectBasePath(state),
});

const enhance = compose(
  connect(mapStateToProps),
  withStyles(styles)
);

const PharmacyInfoSubNavBar = enhance(InnerPharmacyInfoSubNavBar);

InnerPharmacyInfoSubNavBar.propTypes = {
  classes: PropTypes.object.isRequired,
  pharmacy: PropTypes.object.isRequired,
  basePath: PropTypes.string.isRequired,
};

export default PharmacyInfoSubNavBar;
