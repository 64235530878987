import { withToggleState } from '@apoly-42/apoly-components';
import { withHandler } from '@apoly-42/apoly-utils';
import { AppBar, Grid, Toolbar, withStyles } from '@material-ui/core';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { flex, flexRight } from '../../../../constants/styles/commonClasses';
import { selectUser } from '../../../../redux/auth/reducer';
import {
  selectBasePath,
  selectPharmacy,
} from '../../../../redux/shopPharmacy/reducer';
import { partnerPharmacyPath } from '../../../../routes/paths';
import ShoppingCartButton from '../../../shoppingCartButton/ShoppingCartButton';
import RightDrawerSetPharmacyPostal from '../drawer/RightDrawerSetPharmacyPostal';
import SubNavBarPostal from '../subNavbar/SubNavBarPostal';
import UserMenuForTabbar from '../userMenu/UserMenuForTabbar';
import BrandName from './navBarComponents/BrandName';
import FindMedicButton from './navBarComponents/FindMedicButton';
import ToggleDrawerButton from './navBarComponents/ToggleDrawerButton';
import SearchBar from './SearchBar';

const styles = theme => ({
  flexAndFlexRight: {
    ...flexRight,
    ...flex,
  },
  fixedDoubleNavbarHeight: {
    height: 56 * 2,
    [`${theme.breakpoints.up('xs')} and (orientation: landscape)`]: {
      height: 48 * 2,
    },
    [theme.breakpoints.up('sm')]: {
      height: 64 * 2,
    },
  },
});

const InnerNavBarSetPharmacyViewPostal = ({
  searchBarOpen,
  toggleSearchBar,
  drawerOpen,
  toggleDrawer,
  classes,
  toggleSearchBarAndDrawer,
  basePath,
  pharmacy,
  onEnter,
  user,
  logOutUser,
  logOut,
}) => (
  <div className={classes.fixedDoubleNavbarHeight}>
    <AppBar position="static">
      <Grid container justify="center" spacing={0}>
        <Grid item xs={12} lg={10} style={{ flexGrow: 1 }}>
          <Toolbar>
            <BrandName url={partnerPharmacyPath(basePath)} />
            <div style={flex}>
              <FindMedicButton
                toggleSearchBar={toggleSearchBar}
                disabled={searchBarOpen}
              />
              <ShoppingCartButton />
              {user && <UserMenuForTabbar logOut={logOut} />}
              <ToggleDrawerButton toggleDrawer={toggleDrawer} />
            </div>
          </Toolbar>
        </Grid>
        <Grid item xs={12} lg={10}>
          <SubNavBarPostal pharmacy={pharmacy} />
        </Grid>
      </Grid>
    </AppBar>
    <RightDrawerSetPharmacyPostal
      open={drawerOpen}
      toggleSearchBarAndDrawer={toggleSearchBarAndDrawer}
      handleDrawer={toggleDrawer}
      logOutUser={logOutUser}
      pharmacy={pharmacy}
      basePath={basePath}
    />
    <SearchBar
      onEnter={onEnter}
      pharmacy={pharmacy}
      open={searchBarOpen}
      closeSearchBar={toggleSearchBar}
    />
  </div>
);

const mapStateToProps = state => ({
  user: selectUser(state),
  basePath: selectBasePath(state),
  pharmacy: selectPharmacy(state),
});

const enhance = compose(
  withToggleState('drawerOpen', 'toggleDrawer', false),
  withToggleState('searchBarOpen', 'toggleSearchBar', false),
  withHandler('logOutUser', props => () => [
    props.toggleDrawer(),
    props.logOut(),
  ]),
  withHandler('toggleSearchBarAndDrawer', props => () => [
    props.toggleDrawer(),
    props.toggleSearchBar(),
  ]),
  withStyles(styles),
  connect(mapStateToProps)
);

const NavBarSetPharmacyViewPostal = enhance(InnerNavBarSetPharmacyViewPostal);

NavBarSetPharmacyViewPostal.propTypes = {
  onEnter: PropTypes.func,
  logOut: PropTypes.func.isRequired,
};

NavBarSetPharmacyViewPostal.defaultProps = {
  onEnter: null,
};

InnerNavBarSetPharmacyViewPostal.propTypes = {
  classes: PropTypes.object.isRequired,
  pharmacy: PropTypes.object.isRequired,
  basePath: PropTypes.string.isRequired,
  drawerOpen: PropTypes.bool.isRequired,
  searchBarOpen: PropTypes.bool.isRequired,
  toggleDrawer: PropTypes.func.isRequired,
  toggleSearchBar: PropTypes.func.isRequired,
  toggleSearchBarAndDrawer: PropTypes.func.isRequired,
  user: PropTypes.object,
  logOutUser: PropTypes.func.isRequired,
  ...NavBarSetPharmacyViewPostal.propTypes,
};

InnerNavBarSetPharmacyViewPostal.defaultProps = {
  user: null,
  ...NavBarSetPharmacyViewPostal.defaultProps,
};

export default NavBarSetPharmacyViewPostal;
