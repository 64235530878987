import { createContainer, onPropsDidUpdate } from '@apoly-42/apoly-utils';
import firebase from 'firebase/app';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import withHandlers from 'recompose/withHandlers';
import { loggedOutPath, loggedOutSetPharmacyPath } from '../../routes/paths';

const LogOutContainer = createContainer(
  withRouter,
  withHandlers({
    logOutUserWithRedirect: props => () =>
      firebase
        .auth()
        .signOut()
        .then(() => [
          props.history.push({
            pathname: props.basePath
              ? loggedOutSetPharmacyPath(props.basePath)
              : loggedOutPath(),
            state: { redirectPath: props.currentPath },
          }),
          props.setLogOut(false),
        ]),
  }),
  onPropsDidUpdate(
    ['logOut'],
    props => props.logOut && props.logOutUserWithRedirect()
  )
);

LogOutContainer.propTypes = {
  basePath: PropTypes.string,
  currentPath: PropTypes.string.isRequired,
  logOut: PropTypes.bool.isRequired,
  setLogOut: PropTypes.func.isRequired,
};
LogOutContainer.defaultProps = {
  basePath: null,
};

export default LogOutContainer;
