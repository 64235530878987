import { I18nMessage } from '@apoly-42/apoly-components';
import { UrlList } from '@apoly-42/material-ui';
import { Grid } from '@material-ui/core';
import PropTypes from 'prop-types';
import React from 'react';
import { commonMessages } from '../../../constants/messages/commonMessages';
import { BRAND_NAME } from '../../../environment';
import {
  aboutUsPath,
  contactPath,
  contactPathSetPharmacy,
  faqPath,
  faqSetPharmacyPath,
  jobsPath,
  jobsSetPharmacyPath,
  landingPath,
  pressPath,
  pressSetPharmacyPath,
} from '../../../routes/paths';
import FooterHeadline from './FooterHeadline';

const getLinkList = basePath =>
  [
    {
      text: <I18nMessage message={commonMessages.linkToLandingPage} />,
      url: landingPath(),
    },
    {
      text: <I18nMessage message={commonMessages.faq} />,
      url: basePath ? faqSetPharmacyPath(basePath) : faqPath(),
    },
    {
      text: <I18nMessage message={commonMessages.contact} />,
      url: basePath ? contactPathSetPharmacy(basePath) : contactPath(),
    },
    {
      text: <I18nMessage message={commonMessages.pressAndPartners} />,
      url: basePath ? pressSetPharmacyPath(basePath) : pressPath(),
    },
    {
      text: (
        <I18nMessage
          message={commonMessages.jobsAtBrand}
          values={{ brand: BRAND_NAME }}
        />
      ),
      url: basePath ? jobsSetPharmacyPath(basePath) : jobsPath(),
    },
    !basePath && {
      text: <I18nMessage message={commonMessages.aboutUs} />,
      url: aboutUsPath(),
    },
  ].filter(Boolean);

const MoreInformation = ({ basePath }) => (
  <Grid container spacing={16}>
    <Grid item xs={12}>
      <FooterHeadline>
        <I18nMessage message={commonMessages.moreInformation} />
      </FooterHeadline>
    </Grid>
    <Grid item xs={12} style={{ color: '#fff' }}>
      <UrlList linkList={getLinkList(basePath)} />
    </Grid>
  </Grid>
);

MoreInformation.propTypes = {
  basePath: PropTypes.string.isRequired,
};

export default MoreInformation;
