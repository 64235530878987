import { I18nMessage } from '@apoly-42/apoly-components';
import { Typography, withStyles } from '@material-ui/core';
import PropTypes from 'prop-types';
import React from 'react';
import { defineMessages } from 'react-intl';
import { Link } from 'react-router-dom';
import { commonMessages } from '../../../constants/messages/commonMessages';
import {
  agbPath,
  dataSecurityPath,
  dataSecuritySetPharmacyPath,
  imprintPath,
  imprintSetPharmacyPath,
} from '../../../routes/paths';

/* eslint-disable react/no-array-index-key */

const styles = {
  container: {
    display: 'flex',
    margin: 'auto',
    '@media(max-width: 600px)': {
      display: 'inline',
    },
  },
  item: {
    margin: '0px 8px',
  },
  link: {
    textDecoration: 'none',
    color: '#fff',
    '&:hover': {
      textDecoration: 'underline',
    },
  },
};

const messages = defineMessages({
  agbDownload: {
    id: 'agbDownload',
    defaultMessage: 'conditions of use & sale (pdf-download)',
  },
  host: {
    id: 'host',
    defaultMessage: '© 2015‑2017 apoly.de',
  },
});

const LawAgreements = ({ classes, basePath }) => (
  <div className={classes.container}>
    <a href={agbPath()} target="_blank">
      <Typography className={classes.item}>
        <I18nMessage message={messages.agbDownload} />
      </Typography>
    </a>
    <Typography className={classes.item}>
      <Link
        to={
          basePath ? dataSecuritySetPharmacyPath(basePath) : dataSecurityPath()
        }
        className={classes.link}
      >
        <I18nMessage message={commonMessages.dataSecurity} />
      </Link>
    </Typography>
    <Typography className={classes.item}>
      <Link
        to={basePath ? imprintSetPharmacyPath(basePath) : imprintPath()}
        className={classes.link}
      >
        <I18nMessage message={commonMessages.imprint} />
      </Link>
    </Typography>
  </div>
);

LawAgreements.propTypes = {
  classes: PropTypes.object.isRequired,
  basePath: PropTypes.string.isRequired,
};

export default withStyles(styles)(LawAgreements);
