import { createContainer, onMount } from '@apoly-42/apoly-utils';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { withQueryString } from '../../routes/paths';

const SCRIPT_URL = 'https://apolyio.bitrix24.de/bitrix/js/crm/form_loader.js';

const CONTACT_FORM_ID = '24';
const CONTACT_FORM_SEC = '7fccn0';

const CONTACT_FORM_CLASS_NAME = `b24-web-form-popup-btn-${CONTACT_FORM_ID}`;
const GLOBAL_BITRIX_NAME = 'b24form';

/* eslint-disable no-param-reassign,dot-notation */
const loadBitrix = () => {
  window['Bitrix24FormObject'] = GLOBAL_BITRIX_NAME;
  window[GLOBAL_BITRIX_NAME] =
    window[GLOBAL_BITRIX_NAME] ||
    (newElement => {
      newElement.ref = SCRIPT_URL;
      window[GLOBAL_BITRIX_NAME].forms = window[GLOBAL_BITRIX_NAME].forms || [];
      window[GLOBAL_BITRIX_NAME].forms.push(newElement);
    });

  if (window[GLOBAL_BITRIX_NAME]['forms']) {
    return;
  }

  const scriptEle = document.createElement('script');
  scriptEle.async = 1;
  scriptEle.src = `${SCRIPT_URL}?${1 * new Date()}`;

  const otherScriptEle = document.getElementsByTagName('script')[0];
  otherScriptEle.parentNode.insertBefore(scriptEle, otherScriptEle);

  window[GLOBAL_BITRIX_NAME]({
    id: CONTACT_FORM_ID,
    lang: 'de',
    sec: CONTACT_FORM_SEC,
    type: 'button',
    click: '',
  });
};

const addMaIdToUrl = (location, maid) =>
  withQueryString(location.pathname + location.search, { maid });

/* eslint-enable no-param-reassign,dot-notation */
const LoadBitrix24ContactForm = createContainer(
  withRouter,
  onMount(props =>
    props.history.replace(addMaIdToUrl(props.location, props.maid))
  ),
  onMount(loadBitrix),
  // onMount(props => props.setBitrixFormClassName(CONTACT_FORM_CLASS_NAME))
  onMount(props => props.setBitrixFormClassName(CONTACT_FORM_CLASS_NAME))
);

LoadBitrix24ContactForm.propTypes = {
  // the noPartnerPharmacyId (needed for bitrix-tracking)
  maid: PropTypes.any.isRequired,
  setBitrixFormClassName: PropTypes.func.isRequired,
};

export default LoadBitrix24ContactForm;
