import { Typography } from '@material-ui/core';
import PropTypes from 'prop-types';
import React from 'react';
import Link from 'react-router-dom/Link';
import { BRAND_NAME } from '../../../../../environment';

const BrandName = ({ url, ...props }) => (
  <Typography
    style={{ textDecoration: 'none', flex: 1 }}
    component={Link}
    to={url}
    variant="title"
    color="inherit"
    noWrap
    {...props}
  >
    {BRAND_NAME}
  </Typography>
);

BrandName.propTypes = {
  url: PropTypes.string.isRequired,
};

BrandName.defaultProps = {};

export default BrandName;
