import PropTypes from 'prop-types';
import React from 'react';
import PharmacyOpeningTime from './PharmacyOpeningTime';

const NextPharmacyOpeningTime = props => (
  <PharmacyOpeningTime
    enhancedNextOpeningTimes={props.enhancedPharmacy.nextOpeningTimes}
    isOpen={props.enhancedPharmacy.isOpen}
  />
);

NextPharmacyOpeningTime.propTypes = {
  enhancedPharmacy: PropTypes.object.isRequired,
};

NextPharmacyOpeningTime.defaultProps = {};

export default NextPharmacyOpeningTime;
