import { I18nMessage } from '@apoly-42/apoly-components';
import { Button, Grid, Typography, withStyles } from '@material-ui/core';
import PropTypes from 'prop-types';
import React from 'react';
import { defineMessages } from 'react-intl';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { compose } from 'recompose';
import { commonMessages } from '../../../../constants/messages/commonMessages';
import {
  flex,
  fullWidth,
  marginAuto,
} from '../../../../constants/styles/commonClasses';
import { selectBasePath } from '../../../../redux/shopPharmacy/reducer';
import { userPath, userPathWithBase } from '../../../../routes/paths';
import AvatarForTabbar from './AvatarForTabbar';

const styles = {
  withWordWrap: {
    wordWrap: 'break-word',
  },
};

const messages = defineMessages({
  loggedInWithCredentials: {
    id: 'loggedInWithCredentials',
    defaultMessage: "You're currently logged in with {displayName} ({email}).",
  },
});

const InnerUserLogOut = ({ classes, basePath, open, logOut, user }) => (
  <Grid container spacing={16}>
    <Grid item xs={2} sm={4} style={flex}>
      <AvatarForTabbar open={open} style={marginAuto} />
    </Grid>
    <Grid item xs={10} sm={8}>
      <Typography className={classes.withWordWrap}>
        <I18nMessage
          message={messages.loggedInWithCredentials}
          values={{ displayName: <b>{user.displayName}</b>, email: user.email }}
        />
      </Typography>
    </Grid>
    <Grid item xs={12}>
      <Button
        style={fullWidth}
        color="primary"
        variant="raised"
        component={Link}
        to={basePath ? userPathWithBase(basePath) : userPath()}
      >
        <I18nMessage message={commonMessages.changeUserData} />
      </Button>
    </Grid>
    <Grid item xs={12}>
      <Button
        style={fullWidth}
        color="secondary"
        variant="raised"
        onClick={logOut}
      >
        <I18nMessage message={commonMessages.logOut} />
      </Button>
    </Grid>
  </Grid>
);

const mapStateToProps = state => ({
  basePath: selectBasePath(state),
});

const enhance = compose(
  connect(mapStateToProps),
  withStyles(styles)
);

const UserLogOut = enhance(InnerUserLogOut);

UserLogOut.propTypes = {
  user: PropTypes.object.isRequired,
  logOut: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
};

InnerUserLogOut.propTypes = {
  basePath: PropTypes.string,
  ...UserLogOut.propTypes,
};

InnerUserLogOut.defaultProps = {
  basePath: '',
};

export default UserLogOut;
