import { OnDidMountOrUpdate } from '@apoly-42/apoly-utils';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { Route, withRouter } from 'react-router-dom';
import { compose } from 'recompose';
import LoadPageCategory from '../components/LoadPageCategory';
import {
  LoadAboutUsPage,
  LoadContactPage,
  LoadDataSecurityPage,
  LoadFaqPage,
  LoadImprintPage,
  LoadJobsPage,
  LoadLandingPage,
  LoadLoggedOutPage,
  LoadLoginPage,
  LoadPressPage,
  LoadRegisterPage,
  LoadSubmitContactFormSuccessfulPage,
} from '../components/pages/LoadPagesAsync';
import LoadChangeUserDataPage from '../components/pages/userSubPages/LoadChangeUserDataPage';
import PrivateRoute from '../components/routeComponenents/PrivateRoute';
import Route404Switch from '../containers/Route404Switch';
import {
  selectBasePath,
  setBasePath,
  setPharmacy,
} from '../redux/shopPharmacy/reducer';
import {
  aboutUsPath,
  agbPath,
  choosePharmacyPath,
  contactPath,
  dataSecurityPath,
  faqPath,
  imprintPath,
  jobsPath,
  landingPath,
  loggedOutPath,
  loginPath,
  noPartnerPharmacySeoRequestSuccessfulPath,
  pressPath,
  registerPath,
  userPath,
} from './paths';
import renderSearchPharmacyRoute from './renderSearchPharmacyRoute';

const redirectToAgbs = () => {
  window.location.href = agbPath();
  return null;
};

const InnerRoutesWithoutSetPharmacy = props => (
  <React.Fragment>
    <OnDidMountOrUpdate
      handler={() => [props.setPharmacy(null), props.setBasePath('')]}
    />

    <Route404Switch>
      {/* this urls should not get touched, cause they are given on many external places */}
      <Route exact path="/agbs" render={redirectToAgbs} />
      <Route exact path="/datenschutz" component={LoadDataSecurityPage} />
      {/* END_STATIC_URLS */}
      <Route exact path="/" component={LoadLandingPage} />
      <Route exact path={landingPath()} component={LoadLandingPage} />
      <Route exact path={loginPath()} component={LoadLoginPage} />
      <Route exact path={loggedOutPath()} component={LoadLoggedOutPage} />
      <Route exact path={registerPath()} component={LoadRegisterPage} />
      <Route exact path={contactPath()} component={LoadContactPage} />
      <Route exact path={faqPath()} component={LoadFaqPage} />
      <Route exact path={pressPath()} component={LoadPressPage} />
      <Route exact path={jobsPath(props.basePath)} component={LoadJobsPage} />
      <Route exact path={imprintPath()} component={LoadImprintPage} />
      <Route exact path={dataSecurityPath()} component={LoadDataSecurityPage} />
      <Route exact path={aboutUsPath()} component={LoadAboutUsPage} />
      <Route
        exact
        path={noPartnerPharmacySeoRequestSuccessfulPath()}
        component={LoadSubmitContactFormSuccessfulPage}
      />
      {renderSearchPharmacyRoute(choosePharmacyPath())}
      <PrivateRoute path={userPath()} component={LoadChangeUserDataPage} />)
      <Route
        path="/:urlCode"
        render={({
          match: {
            params: { urlCode },
          },
        }) => <LoadPageCategory key={urlCode} urlCode={urlCode} />}
      />
    </Route404Switch>
  </React.Fragment>
);

const mapStateToProps = state => ({
  basePath: selectBasePath(state),
});

const mapDispatchToProps = {
  setPharmacy,
  setBasePath,
};

const enhance = compose(
  connect(
    mapStateToProps,
    mapDispatchToProps
  ),
  withRouter
);

const RoutesWithoutSetPharmacy = enhance(InnerRoutesWithoutSetPharmacy);

RoutesWithoutSetPharmacy.propTypes = {
  location: PropTypes.object.isRequired,
};

InnerRoutesWithoutSetPharmacy.propTypes = {
  setPharmacy: PropTypes.func.isRequired,
  setBasePath: PropTypes.func.isRequired,
  ...RoutesWithoutSetPharmacy.propTypes,
};

export default RoutesWithoutSetPharmacy;
