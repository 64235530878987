import { I18nMessage } from '@apoly-42/apoly-components';
import { Typography } from '@material-ui/core';
import React from 'react';
import { defineMessages } from 'react-intl';
import { commonMessages } from '../../../constants/messages/commonMessages';

/* eslint-disable react/no-array-index-key */

const messages = defineMessages({
  orderEveryProductByPhone: {
    id: 'orderEveryProductByPhone',
    defaultMessage:
      '1 You can order every medicine at your local pharmacy, if not regulated by law, by phone, even if it is not listed on this website',
  },
  priceAndAvailabilityForPharmacyOnly: {
    id: 'priceAndAvailabilityForPharmacyOnly',
    defaultMessage:
      '2 Price and availability shown on this page just apply to your selected pharmacy.',
  },
  paymentMethodsDependOnPharmacy: {
    id: 'paymentMethodsDependOnPharmacy',
    defaultMessage: '3 Available payment methods depend on selected pharmacy.',
  },
});

const footNotes = [
  <I18nMessage message={messages.orderEveryProductByPhone} />,
  <I18nMessage message={messages.priceAndAvailabilityForPharmacyOnly} />,
  <I18nMessage message={messages.paymentMethodsDependOnPharmacy} />,
];

const FootNotes = () => (
  <React.Fragment>
    <Typography paragraph>
      <I18nMessage message={commonMessages.risksAndSideEffects} />
    </Typography>
    {footNotes &&
      footNotes.map((footNote, index) => (
        <Typography key={index} variant="caption">
          {footNote}
        </Typography>
      ))}
  </React.Fragment>
);

export default FootNotes;
