import { I18nMessage, withToggleState } from '@apoly-42/apoly-components';
import { withHandler } from '@apoly-42/apoly-utils';
import { faChevronDoubleRight } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  AppBar,
  Button,
  Grid,
  Hidden,
  Toolbar,
  withStyles,
} from '@material-ui/core';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import Link from 'react-router-dom/Link';
import { compose } from 'recompose';
import { commonMessages } from '../../../../constants/messages/commonMessages';
import { flex } from '../../../../constants/styles/commonClasses';
import { selectUser } from '../../../../redux/auth/reducer';
import { choosePharmacyPath, landingPath } from '../../../../routes/paths';
import RightDrawer from '../drawer/RightDrawer';
import UserMenuForTabbar from '../userMenu/UserMenuForTabbar';
import BrandName from './navBarComponents/BrandName';
import ToggleDrawerButton from './navBarComponents/ToggleDrawerButton';

// check implementation when changes exist
// Toolbar: https://github.com/mui-org/material-ui/blob/v1-beta/packages/material-ui/src/Toolbar/Toolbar.js
// used mixin: https://github.com/mui-org/material-ui/blob/v1-beta/packages/material-ui/src/styles/createMixins.js
const styles = theme => ({
  fixedNavbarHeight: {
    height: 56,
    [`${theme.breakpoints.up('xs')} and (orientation: landscape)`]: {
      height: 48,
    },
    [theme.breakpoints.up('sm')]: {
      height: 64,
    },
  },
});

const InnerNavBarView = ({
  drawerOpen,
  toggleDrawer,
  logOutUser,
  logOut,
  user,
  classes,
}) => (
  <div className={classes.fixedNavbarHeight}>
    <AppBar position="static">
      <Grid container justify="center" spacing={0}>
        <Grid item xs={12} lg={10} style={{ flexGrow: 1 }}>
          <Toolbar>
            <BrandName url={landingPath()} />
            <div style={flex}>
              <Hidden smDown>
                <Button
                  color="inherit"
                  component={Link}
                  to={choosePharmacyPath()}
                >
                  <span>
                    <I18nMessage message={commonMessages.findPharmacy} />
                     <FontAwesomeIcon icon={faChevronDoubleRight} />
                  </span>
                </Button>
              </Hidden>
              {user && (
                <div style={{ marginLeft: 16, marginRight: 16 }}>
                  <UserMenuForTabbar logOut={logOut} />
                </div>
              )}
              <ToggleDrawerButton toggleDrawer={toggleDrawer} />
            </div>
          </Toolbar>
        </Grid>
      </Grid>
    </AppBar>
    <RightDrawer
      open={drawerOpen}
      handleDrawer={toggleDrawer}
      logOutUser={logOutUser}
    />
  </div>
);

const mapStateToProps = state => ({
  user: selectUser(state),
});

const enhance = compose(
  withStyles(styles),
  withToggleState('drawerOpen', 'toggleDrawer', false),
  withHandler('logOutUser', props => () => [
    props.logOut(),
    props.toggleDrawer(),
  ]),
  connect(mapStateToProps)
);

const NavBarView = enhance(InnerNavBarView);

NavBarView.propTypes = {
  logOut: PropTypes.func.isRequired,
};

InnerNavBarView.propTypes = {
  drawerOpen: PropTypes.bool.isRequired,
  toggleDrawer: PropTypes.func.isRequired,
  user: PropTypes.object,
  logOutUser: PropTypes.func.isRequired,
  classes: PropTypes.object.isRequired,
  ...NavBarView.propTypes,
};

InnerNavBarView.defaultProps = {
  user: null,
};

export default NavBarView;
