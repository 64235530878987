import { I18nMessage } from '@apoly-42/apoly-components';
import { faMapMarkerAlt, faUserMd } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Grid, Typography } from '@material-ui/core';
import PropTypes from 'prop-types';
import React from 'react';
import { defineMessages } from 'react-intl';
import { flex } from '../../constants/styles/commonClasses';
import { pharmacyMessages } from '../pages/partnerPharmacy/aboutPartnerPharmacy/Pharmacy';
import PharmacyDimdi from '../pages/partnerPharmacy/aboutPartnerPharmacy/PharmacyDimdi';

export const localInformationMessages = defineMessages({
  addressLabel: {
    id: 'addressLabel',
    defaultMessage: 'address',
  },
});

const LocalPharmacyInformation = props => (
  <Grid container spacing={16} justify="center">
    <Grid item xs={12} md={5} style={flex}>
      <FontAwesomeIcon
        fixedWidth
        icon={faUserMd}
        style={{ margin: 'auto 8px', fontSize: '2.2rem' }}
      />
      <div style={{ margin: 'auto 8px' }}>
        <Typography variant="caption">
          <I18nMessage message={pharmacyMessages.owner} />
        </Typography>
        <Typography>{props.owner || '-'}</Typography>
      </div>
    </Grid>
    <Grid item xs={12} md={5} style={flex}>
      <FontAwesomeIcon
        fixedWidth
        icon={faMapMarkerAlt}
        style={{ margin: 'auto 8px', fontSize: '2.2rem' }}
      />
      <div style={{ margin: 'auto 8px' }}>
        <Typography variant="caption">
          <I18nMessage message={localInformationMessages.addressLabel} />
        </Typography>
        <Typography>
          {props.address.street || '-'}, {props.address.zip}
           {props.address.city}
        </Typography>
      </div>
    </Grid>
    <Grid item xs={6} sm={3} md={2} style={flex}>
      {props.dimdiUrl && <PharmacyDimdi url={props.dimdiUrl} />}
    </Grid>
  </Grid>
);

LocalPharmacyInformation.propTypes = {
  address: PropTypes.object.isRequired,
  owner: PropTypes.string,
  dimdiUrl: PropTypes.string,
};

LocalPharmacyInformation.defaultProps = {
  owner: null,
  dimdiUrl: null,
};

export default LocalPharmacyInformation;
