import { withHandler } from '@apoly-42/apoly-utils';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { selectUserId } from '../redux/auth/reducer';
import { selectLocale, setLocale } from '../redux/language/reducer';
import { setLocaleToLocalStorage } from './apis/localStorage/locale';

const mapStateToProps = state => ({
  userId: selectUserId(state),
  locale: selectLocale(state),
});
const mapDispatchToProps = { setLocale };

export const enhanceLocale = compose(
  connect(
    mapStateToProps,
    mapDispatchToProps
  ),
  withHandler('changeLanguage', props => locale => [
    props.setLocale(locale),
    setLocaleToLocalStorage(locale),
  ])
);
