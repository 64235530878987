import {
  first,
  getBrowserLocale,
  onMount,
  renderIfHasProp,
} from '@apoly-42/apoly-utils';
import { pipe } from 'ramda';
import { connect } from 'react-redux';
import compose from 'recompose/compose';
import { selectLocale, setLocale } from '../redux/language/reducer';
import { parseCurrentSearchParams } from '../routes/paths';
import { getLocaleFromLocalStorage } from './apis/localStorage/locale';
import AsyncLanguageLoadable from './languages/AsyncLanguageLoadable';

const mapStateToProps = state => ({
  locale: selectLocale(state),
});

const mapDispatchToProps = { setLocale };

const getLocaleFromQueryString = pipe(
  parseCurrentSearchParams,
  searchParams => searchParams && searchParams.lang
);

const getLocale = () =>
  first(
    getLocaleFromQueryString,
    getLocaleFromLocalStorage,
    getBrowserLocale,
    () => 'de'
  );

const LanguageProvider = compose(
  connect(
    mapStateToProps,
    mapDispatchToProps
  ),
  onMount(props => props.setLocale(getLocale())),
  renderIfHasProp('locale')
)(AsyncLanguageLoadable);

export default LanguageProvider;
