import { I18nMessage } from '@apoly-42/apoly-components';
import { Grid, Typography } from '@material-ui/core';
import React from 'react';
import { commonMessages } from '../../../../../constants/messages/commonMessages';
import {
  flex,
  verticalCenteredInFlex,
} from '../../../../../constants/styles/commonClasses';
import FlagButton from './FlagButton';

const ChangeLocale = () => (
  <Grid container spacing={16}>
    <Grid style={flex} item xs={12}>
      <Typography style={verticalCenteredInFlex}>
        <I18nMessage message={commonMessages.selectLanguage} />:
      </Typography>
    </Grid>
    <Grid item xs={6}>
      <FlagButton language={'en'} title={'English'} flagCode={'gb'} />
    </Grid>
    <Grid item xs={6}>
      <FlagButton language={'de'} title={'Deutsch'} flagCode={'de'} />
    </Grid>
    <Grid item xs={6}>
      <FlagButton language={'ar'} title={'العربية'} flagCode={'sy'} />
    </Grid>
    <Grid item xs={6}>
      <FlagButton language={'tr'} title={'Türkçe'} flagCode={'tr'} />
    </Grid>
    <Grid item xs={6}>
      <FlagButton language={'ru'} title={'Русский'} flagCode={'ru'} />
    </Grid>
  </Grid>
);

export default ChangeLocale;
