import { FlagIcon, I18nMessage } from '@apoly-42/apoly-components';
import { Button, Grid, withStyles } from '@material-ui/core';
import PropTypes from 'prop-types';
import React from 'react';
import { compose } from 'recompose';
import { commonMessages } from '../constants/messages/commonMessages';
import {
  flex,
  verticalCenteredInFlex,
} from '../constants/styles/commonClasses';
import { enhanceLocale } from '../containers/ChangeLocaleContainer';
import FooterHeadline from './layout/footer/FooterHeadline';

const styles = {
  flex,
  verticalCenteredInFlex,
};

const ChangeLocale = ({ classes, changeLanguage, locale }) => (
  <Grid container spacing={16}>
    <Grid item xs={12}>
      <FooterHeadline>
        <I18nMessage message={commonMessages.selectLanguage} />
      </FooterHeadline>
    </Grid>
    <Grid item xs={6} sm={6} md={12} lg={6} xl={6}>
      <Button
        className={classes.flex}
        onClick={() => changeLanguage('en')}
        disabled={locale === 'en'}
      >
        <FlagIcon className={classes.verticalCenteredInFlex} code={'gb'} />
        English
      </Button>
    </Grid>
    <Grid item xs={6} sm={6} md={12} lg={6} xl={6}>
      <Button
        className={classes.flex}
        onClick={() => changeLanguage('de')}
        disabled={locale === 'de'}
      >
        <FlagIcon className={classes.verticalCenteredInFlex} code={'de'} />
        Deutsch
      </Button>
    </Grid>
    <Grid item xs={6} sm={6} md={12} lg={6} xl={6}>
      <Button
        className={classes.flex}
        onClick={() => changeLanguage('ar')}
        disabled={locale === 'ar'}
      >
        <FlagIcon className={classes.verticalCenteredInFlex} code={'sy'} />
        العربية
      </Button>
    </Grid>
    <Grid item xs={6} sm={6} md={12} lg={6} xl={6}>
      <Button
        className={classes.flex}
        onClick={() => changeLanguage('tr')}
        disabled={locale === 'tr'}
      >
        <FlagIcon className={classes.verticalCenteredInFlex} code={'tr'} />
        Türkçe
      </Button>
    </Grid>
    <Grid item xs={6} sm={6} md={12} lg={6} xl={6}>
      <Button
        className={classes.flex}
        onClick={() => changeLanguage('ru')}
        disabled={locale === 'ru'}
      >
        <FlagIcon className={classes.verticalCenteredInFlex} code={'ru'} />
        Русский
      </Button>
    </Grid>
  </Grid>
);

ChangeLocale.propTypes = {
  classes: PropTypes.object.isRequired,
  locale: PropTypes.string.isRequired,
  changeLanguage: PropTypes.func.isRequired,
};

const enhance = compose(
  withStyles(styles),
  enhanceLocale
);

export default enhance(ChangeLocale);
