import { is404 } from '@apoly-42/apoly-utils';
import { DefaultError, ShowComponentWhileLoading } from '@apoly-42/material-ui';
import PropTypes from 'prop-types';
import React from 'react';
import { Route } from 'react-router-dom';
import { compose, withState } from 'recompose';
import LoadCategoryFromUrlCode from '../containers/LoadCategoryFromUrlCode';
import LoadPharmacyShopLoadable from '../containers/loadingContainer/LoadPharmacyShopLoadable';
import {
  LOCAL_URL_FLAG,
  medicineSeoPath,
  noPartnerPharmacySeoPath,
} from '../routes/paths';
import DefaultPageTemplate from './layout/DefaultPageTemplate';
import PageNotFoundError from './PageNotFoundError';
import {
  LoadMedicineSeoPage,
  LoadNoPartnerPharmacySeoPage,
} from './pages/LoadPagesAsync';

const InnerLoadPageCategory = props => (
  <React.Fragment>
    <LoadCategoryFromUrlCode
      setError={props.setError}
      setIsLoading={props.setIsLoading}
      setCategory={props.setCategory}
      urlCode={props.urlCode}
    />

    {props.error && is404(props.error) && <PageNotFoundError />}

    {props.error &&
      !is404(props.error) && (
        <DefaultError baseComponent={DefaultPageTemplate} />
      )}

    {props.isLoading && (
      <ShowComponentWhileLoading isLoading={props.isLoading} />
    )}

    {props.category === 'medicine' && (
      <Route
        path={medicineSeoPath(':medicineUrlCode', ':pzn?')}
        render={({
          match: {
            params: { medicineUrlCode, pzn },
          },
        }) => (
          // set key so that it rerender for changes in pzn
          <LoadMedicineSeoPage
            key={pzn || medicineUrlCode}
            medicineUrlCode={medicineUrlCode}
            pzn={pzn}
          />
        )}
      />
    )}

    {props.category === 'pharmacy' && (
      <LoadPharmacyShopLoadable
        pharmacyUrlCode={props.urlCode}
        postal={LOCAL_URL_FLAG}
      />
    )}

    {props.category === 'noPartnerPharmacy' && (
      <Route
        path={noPartnerPharmacySeoPath(':noPartnerPharmacyUrlCode')}
        render={({
          match: {
            params: { noPartnerPharmacyUrlCode },
          },
        }) => (
          <LoadNoPartnerPharmacySeoPage
            key={noPartnerPharmacyUrlCode}
            noPartnerPharmacyUrlCode={noPartnerPharmacyUrlCode}
          />
        )}
      />
    )}
  </React.Fragment>
);

const enhance = compose(
  withState('category', 'setCategory', null),
  withState('error', 'setError', null),
  withState('isLoading', 'setIsLoading', false)
);

const LoadPageCategory = enhance(InnerLoadPageCategory);

LoadPageCategory.propTypes = {
  urlCode: PropTypes.string.isRequired,
};

InnerLoadPageCategory.propTypes = {
  category: PropTypes.string,
  setCategory: PropTypes.func.isRequired,
  error: PropTypes.object,
  setError: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
  setIsLoading: PropTypes.func.isRequired,
  ...LoadPageCategory.propTypes,
};

InnerLoadPageCategory.defaultProps = {
  category: null,
  error: null,
};

export default LoadPageCategory;
