import { I18nMessage } from '@apoly-42/apoly-components';
import { Grid, Typography, withStyles } from '@material-ui/core';
import PropTypes from 'prop-types';
import React from 'react';
import {
  flex,
  flexAlignFlexStart,
} from '../../../../constants/styles/commonClasses';
import {
  getPaymentMethodImage,
  paymentMethodToMessage,
} from '../../../../utilities/paymentMethod/paymentMethodsUtilities';

const styles = {
  image: {
    maxWidth: '100%',
    margin: 'auto',
  },
};

const PaymentMethods = ({ classes, availablePaymentMethods }) => (
  <Grid container spacing={16}>
    {availablePaymentMethods &&
      availablePaymentMethods.map(paymentMethod => (
        <Grid item xs={6} key={paymentMethod}>
          <Grid container>
            <Grid item xs={4} style={flexAlignFlexStart}>
              <img
                className={classes.image}
                alt={paymentMethod}
                src={getPaymentMethodImage(paymentMethod)}
              />
              <Grid item xs={7} style={flex}>
                <Typography style={{ margin: 'auto 8px' }}>
                  <I18nMessage
                    message={paymentMethodToMessage(paymentMethod)}
                  />
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      ))}
  </Grid>
);

PaymentMethods.propTypes = {
  classes: PropTypes.object.isRequired,
  availablePaymentMethods: PropTypes.array.isRequired,
};

export default withStyles(styles)(PaymentMethods);
