import { faUser } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Avatar, withStyles } from '@material-ui/core';
import PropTypes from 'prop-types';
import React from 'react';
import { defineMessages, injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { selectUser } from '../../../../redux/auth/reducer';

const styles = {
  avatar: {
    width: '100%',
    height: 'auto',
  },
};

const messages = defineMessages({
  yourProfilePicture: {
    id: 'yourProfilePicture',
    defaultMessage: 'your profile picture',
  },
});

const InnerAvatarForTabbar = ({
  classes,
  defaultUserIconSizeTabBar,
  open,
  handleClick,
  user,
  style,
  intl: { formatMessage },
}) => (
  <Avatar
    aria-owns={open}
    aria-haspopup="true"
    onClick={handleClick}
    src={user.photoURL}
    alt={formatMessage(messages.yourProfilePicture)}
    className={classes.avatar}
    style={{
      fontSize: defaultUserIconSizeTabBar,
      cursor: handleClick ? 'pointer' : '',
      ...style,
    }}
  >
    {!user.photoURL && <FontAwesomeIcon icon={faUser} />}
  </Avatar>
);

const mapStateToProps = state => ({
  user: selectUser(state),
});

const enhance = compose(
  withStyles(styles),
  connect(mapStateToProps),
  injectIntl
);

const AvatarForTabbar = enhance(InnerAvatarForTabbar);

AvatarForTabbar.propTypes = {
  handleClick: PropTypes.func,
  open: PropTypes.bool,
  style: PropTypes.object,
};

AvatarForTabbar.defaultProps = {
  handleClick: null,
  open: false,
  style: {},
};

InnerAvatarForTabbar.propTypes = {
  classes: PropTypes.object.isRequired,
  intl: PropTypes.object.isRequired,
  user: PropTypes.object.isRequired,
  defaultUserIconSizeTabBar: PropTypes.number,
  ...AvatarForTabbar.propTypes,
};

InnerAvatarForTabbar.defaultProps = {
  defaultUserIconSizeTabBar: 86,
  ...AvatarForTabbar.defaultProps,
};

export default AvatarForTabbar;
