import { FlagIcon } from '@apoly-42/apoly-components';
import { Button } from '@material-ui/core';
import PropTypes from 'prop-types';
import React from 'react';
import { compose } from 'recompose';
import {
  flex,
  verticalCenteredInFlex,
} from '../../../../../constants/styles/commonClasses';
import { enhanceLocale } from '../../../../../containers/ChangeLocaleContainer';

const FlagButton = ({ changeLanguage, language, locale, title, flagCode }) => (
  <Button
    style={flex}
    onClick={() => changeLanguage(language)}
    disabled={locale === language}
  >
    <FlagIcon style={verticalCenteredInFlex} code={flagCode} />
    {title}
  </Button>
);

FlagButton.propTypes = {
  language: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  flagCode: PropTypes.string.isRequired,
  changeLanguage: PropTypes.func.isRequired,
  locale: PropTypes.string.isRequired,
};

const enhance = compose(enhanceLocale);

export default enhance(FlagButton);
