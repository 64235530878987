import { faBars } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button } from '@material-ui/core';
import PropTypes from 'prop-types';
import React from 'react';
import { defineMessages, injectIntl } from 'react-intl';
import { compose } from 'recompose';

const messages = defineMessages({
  toggleSideBarMenu: {
    id: 'toggleSideBarMenu',
    defaultMessage: 'toggle sidebar menu',
  },
});

const ToggleDrawerButton = ({ toggleDrawer, intl: { formatMessage } }) => (
  <Button
    style={{ minWidth: 0 }}
    color="inherit"
    aria-label={formatMessage(messages.toggleSideBarMenu)}
    onClick={toggleDrawer}
  >
    <span>
      <FontAwesomeIcon icon={faBars} />
    </span>
  </Button>
);

ToggleDrawerButton.propTypes = {
  intl: PropTypes.object.isRequired,
  toggleDrawer: PropTypes.func.isRequired,
};

const enhance = compose(injectIntl);

export default enhance(ToggleDrawerButton);
