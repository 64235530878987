import { I18nMessage } from '@apoly-42/apoly-components';
import { ButtonWithIcon, CustomTooltip } from '@apoly-42/material-ui';
import { faClock } from '@fortawesome/pro-solid-svg-icons';
import { Hidden, withStyles } from '@material-ui/core';
import PropTypes from 'prop-types';
import React from 'react';
import { injectIntl } from 'react-intl';
import { compose } from 'recompose';
import { commonMessages } from '../../../../../constants/messages/commonMessages';
import PharmacyOpeningTime from '../../../../pharmacyOpeningTImes/PharmacyOpeningTime';

const styles = {
  buttonXsTooltip: {
    margin: 'auto 8px',
    '@media (max-width: 600px)': {
      minWidth: 0,
    },
    cursor: 'default',
  },
};

const OpeningTimeTooltip = ({
  classes,
  color,
  style,
  enhancedPharmacy,
  intl: { formatMessage },
}) => (
  <CustomTooltip
    id="open"
    title={
      <PharmacyOpeningTime
        enhancedNextOpeningTimes={enhancedPharmacy.nextOpeningTimes}
        isOpen={enhancedPharmacy.isOpen}
      />
    }
    placement="bottom"
  >
    <ButtonWithIcon
      icon={faClock}
      disableRipple
      className={classes.buttonXsTooltip}
      color={color}
      style={style}
      aria-label={formatMessage(
        enhancedPharmacy.isOpen ? commonMessages.open : commonMessages.closed
      )}
    >
      <Hidden smDown>
        <I18nMessage
          message={
            enhancedPharmacy.isOpen
              ? commonMessages.open
              : commonMessages.closed
          }
        />
      </Hidden>
    </ButtonWithIcon>
  </CustomTooltip>
);

OpeningTimeTooltip.propTypes = {
  intl: PropTypes.object.isRequired,
  classes: PropTypes.object.isRequired,
  enhancedPharmacy: PropTypes.object.isRequired,
  style: PropTypes.object.isRequired,
  color: PropTypes.string.isRequired,
};

const enhance = compose(
  withStyles(styles),
  injectIntl
);

export default enhance(OpeningTimeTooltip);
