import { I18nMessage } from '@apoly-42/apoly-components';
import { ButtonWithIcon, ListWithBulletPoints } from '@apoly-42/material-ui';
import {
  faChevronDoubleRight,
  faShippingFast,
} from '@fortawesome/pro-regular-svg-icons';
import { faCheck } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Avatar,
  Card,
  CardContent,
  CardHeader,
  CardMedia,
  Hidden,
  withStyles,
} from '@material-ui/core';
import PropTypes from 'prop-types';
import React from 'react';
import { defineMessages, injectIntl } from 'react-intl';
import { Link } from 'react-router-dom';
import { compose, withState } from 'recompose';
import defaultPic from '../../../constants/assets/germanTownImgs/berlin-skyline-fallback.png';
import happyPill from '../../../constants/assets/Pille-Nurse-Stimmung-happy.png';
import {
  flex,
  flexAlignFlexStart,
} from '../../../constants/styles/commonClasses';
import {
  gridArea,
  pharmacyCtaGrid,
} from '../../../constants/styles/cssEmotionClasses';
import LoadGermanCityPicturesLoadable from '../../../containers/loadGermanCityPictures/LoadGermanCityPicturesLoadable';
import { BRAND_NAME } from '../../../environment';
import { pharmacyShopRouteLocal } from '../../../routes/paths';
import PharmacyDelivery from '../../pharmacyDeliveryTimes/PharmacyDelivery';
import { noPartnerPharmacyCtaMessages } from '../noPartnerPharmacySeo/noPartnerPharmacyAction/NoPartnerPharmacyCta';
import { noPartnerCtaHeaderMessages } from '../noPartnerPharmacySeo/noPartnerPharmacyAction/NoPartnerPharmacyCtaCardHeader';

export const partnerPharmacyCtaMessages = defineMessages({
  onlineShoppingAtApoly: {
    id: 'onlineShoppingAtApoly',
    defaultMessage: 'online shopping at apoly.de',
  },
  goToShop: {
    id: 'goToShop',
    defaultMessage: 'go to shop',
  },
});

const bulletPoints = [
  <I18nMessage
    message={partnerPharmacyCtaMessages.onlineShoppingAtApoly}
    values={{ brand: BRAND_NAME }}
  />,
  <I18nMessage message={noPartnerPharmacyCtaMessages.sameDayDelivery} />,
  <I18nMessage message={noPartnerPharmacyCtaMessages.allOnline} />,
];

const styles = theme => ({
  avatar: {
    backgroundColor: theme.palette.primary.main,
  },
});

const InnerPartnerPharmacyCta = props => (
  <React.Fragment>
    <LoadGermanCityPicturesLoadable
      zip={props.pharmacy.address.zip}
      setPictureUrl={props.setCityPictureUrl}
    />

    <Card>
      <CardHeader
        avatar={
          <Avatar className={props.classes.avatar}>
            <FontAwesomeIcon icon={faShippingFast} />
          </Avatar>
        }
        title={
          <I18nMessage message={noPartnerCtaHeaderMessages.orderNowOnline} />
        }
        subheader={
          <PharmacyDelivery
            enhancedNextCourierTimeSpans={props.nextCourierTimeSpans}
          />
        }
      />

      <CardMedia image={props.cityPictureUrl} style={{ minHeight: 200 }} />

      <CardContent>
        <div className={pharmacyCtaGrid}>
          <Hidden only="md">
            <div className={gridArea('happyPill')}>
              <div style={flexAlignFlexStart}>
                <img
                  src={happyPill}
                  style={{
                    maxWidth: '100%',
                    margin: 'auto',
                    maxHeight: '100%',
                  }}
                  alt={props.intl.formatMessage(
                    noPartnerPharmacyCtaMessages.happyPill,
                    { brand: BRAND_NAME }
                  )}
                />
              </div>
            </div>
          </Hidden>
          <div className={gridArea('list')}>
            <ListWithBulletPoints
              listArray={bulletPoints}
              icon={faCheck}
              iconFontSize="1.25rem"
            />
          </div>
          <div className={gridArea('cta')}>
            <div style={flex}>
              <div style={{ marginLeft: 'auto' }}>
                <ButtonWithIcon
                  component={Link}
                  to={pharmacyShopRouteLocal(props.pharmacy.urlCode)}
                  iconRight
                  icon={faChevronDoubleRight}
                  variant="raised"
                  color="secondary"
                >
                  <I18nMessage message={partnerPharmacyCtaMessages.goToShop} />
                </ButtonWithIcon>
              </div>
            </div>
          </div>
        </div>
      </CardContent>
    </Card>
  </React.Fragment>
);

const enhance = compose(
  withStyles(styles),
  injectIntl,
  withState('cityPictureUrl', 'setCityPictureUrl', defaultPic)
);

const PartnerPharmacyCta = enhance(InnerPartnerPharmacyCta);

PartnerPharmacyCta.propTypes = {
  pharmacy: PropTypes.object.isRequired,
  nextCourierTimeSpans: PropTypes.array.isRequired,
};

InnerPartnerPharmacyCta.propTypes = {
  classes: PropTypes.object.isRequired,
  intl: PropTypes.object.isRequired,
  cityPictureUrl: PropTypes.string.isRequired,
  setCityPictureUrl: PropTypes.func.isRequired,
  ...PartnerPharmacyCta.propTypes,
};

InnerPartnerPharmacyCta.defaultProps = {};

export default PartnerPharmacyCta;
