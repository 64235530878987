import { createGenerateClassName, jssPreset } from '@material-ui/core';
import { create } from 'jss';
import rtl from 'jss-rtl';
import PropTypes from 'prop-types';
import React from 'react';
import JssProvider from 'react-jss/lib/JssProvider';

const generateClassName = createGenerateClassName();
// for RTL-suppoert (arabic language)
const jss = create({ plugins: [...jssPreset().plugins, rtl()] });
// We define a custom insertion point that JSS will look for injecting the styles in the DOM.
// so other CSS-in-JS-lib (styled-components, emotion, ...) will work correctly (see https://material-ui.com/customization/css-in-js/#css-injection-order)
jss.options.insertionPoint = document.getElementById('jss-insertion-point');

const JssAppProvider = props => (
  <JssProvider jss={jss} generateClassName={generateClassName}>
    {props.children}
  </JssProvider>
);

JssAppProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

JssAppProvider.defaultProps = {};

export default JssAppProvider;
