import { PharmacyTheme } from '../PharmacyThemeInterface';
import pic from './assets/veritas_slider3.jpg';
import { LindenApothekeNavbar } from './koenigs_apo_customization/customizedNavBar/LindenApothekeNavbar';
import logo from './koenigs_apo_customization/assets/koenigs-apo-logo.jpeg';
import {
  getkoenigsApoInfoBoxContent,
  koenigsApoLandingSlider,
} from './koenigs_apo_customization/assets/data';
import React from 'react';
import PharmacySlider from './LandingPageSlider/PharmacySlider';

export const koenigsApoCustomPalette = {
  primary: {
    main: '#2c623c',
  },
  secondary: {
    main: '#fdc909',
  },
};

const KoenigsApoPanoramaComponent: React.SFC = props => (
  <PharmacySlider sliderImages={koenigsApoLandingSlider}>
    {props.children}
  </PharmacySlider>
);

export const testCustomisations: Partial<PharmacyTheme> = {
  palette: koenigsApoCustomPalette,
  logo,
  customNavBarComponent: LindenApothekeNavbar,
  panoramaPic: pic,
  customInfoBoxContent: getkoenigsApoInfoBoxContent(koenigsApoCustomPalette),
  PanoramaComponent: KoenigsApoPanoramaComponent,
};
