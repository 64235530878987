import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Typography, withStyles } from '@material-ui/core';
import PropTypes from 'prop-types';
import React from 'react';

const styles = {
  textWithIcon: {
    display: 'flex',
    color: 'inherit',
    height: '100%',
  },
  text: {
    margin: 'auto 8px',
  },
};

const TextWithIcon = ({ classes, text, icon }) => (
  <div className={classes.textWithIcon}>
    <div className={classes.text}>
      <FontAwesomeIcon icon={icon} />
    </div>
    <Typography style={{ margin: 'auto' }} color="inherit">
      {text}
    </Typography>
  </div>
);

TextWithIcon.propTypes = {
  classes: PropTypes.object.isRequired,
  icon: PropTypes.object.isRequired,
  text: PropTypes.any.isRequired,
};

export default withStyles(styles)(TextWithIcon);
