import { DefaultError } from '@apoly-42/material-ui';
import PropTypes from 'prop-types';
import React from 'react';
import { Helmet } from 'react-helmet';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { compose } from 'recompose';
import { selectLocale } from '../../redux/language/reducer';
import SyncServerTime from '../../redux/serverTime/containers/SyncServerTime';
import { selectServerTime } from '../../redux/serverTime/serverTime';
import { selectServerTimeError } from '../../redux/serverTime/serverTimeLoadingState';
import SyncShoppingCartStateWithLocalStorage from '../../redux/shoppingCart/containers/SyncShoppingCartStateWithLocalStorage';
import AppRoutes from '../../routes/AppRoutes';

// show error only when serverTime is not loaded
const isError = (serverTime, syncServerTimeError) =>
  syncServerTimeError && !serverTime;

const InnerAppPage = ({ serverTime, syncServerTimeError, locale }) => (
  <React.Fragment>
    <SyncShoppingCartStateWithLocalStorage />
    <SyncServerTime />

    {locale && <Helmet htmlAttributes={{ lang: locale }} />}

    {isError(serverTime, syncServerTimeError) ? (
      <DefaultError />
    ) : (
      <AppRoutes />
    )}
  </React.Fragment>
);

const mapStateToProps = state => ({
  serverTime: selectServerTime(state),
  syncServerTimeError: selectServerTimeError(state),
  locale: selectLocale(state),
});

const enhance = compose(
  // adding withRouter, cause connect blocks the rerendering on route-changing: https://github.com/ReactTraining/react-router/blob/master/packages/react-router/docs/guides/blocked-updates.md
  withRouter,
  connect(mapStateToProps)
);

const AppPage = enhance(InnerAppPage);

InnerAppPage.propTypes = {
  serverTime: PropTypes.string,
  syncServerTimeError: PropTypes.object,
  locale: PropTypes.string,
};

InnerAppPage.defaultProps = {
  serverTime: null,
  syncServerTimeError: null,
  locale: null,
};

export default AppPage;
