import { I18nMessage } from '@apoly-42/apoly-components';
import { ButtonWithIcon } from '@apoly-42/material-ui';
import { faChevronDoubleRight } from '@fortawesome/pro-regular-svg-icons';
import { Grid, Typography } from '@material-ui/core';
import PropTypes from 'prop-types';
import React from 'react';
import { defineMessages } from 'react-intl';
import { compose, pure, withState } from 'recompose';
import { flex } from '../../../../constants/styles/commonClasses';
import LoadBitrix24ContactForm from '../../../../containers/noPartnerPharmacy/LoadBitrix24ContactForm';
import { BRAND_NAME } from '../../../../environment';

const messages = defineMessages({
  contactPharmacy: {
    id: 'contactPharmacy',
    defaultMessage: 'contact pharmacy',
  },
  contactNoPartnerPharmacyInfoText: {
    id: 'contactNoPartnerPharmacyInfoText',
    defaultMessage: 'Apoly will forward your request to this pharmacy',
  },
});

const InnerContactNoPartnerPharmacyButton = props => (
  <React.Fragment>
    <LoadBitrix24ContactForm
      setBitrixFormClassName={props.setBitrixFormClassName}
      maid={props.noPartnerPharmacy.noPartnerPharmacyId}
    />

    <Grid container spacing={8}>
      <Grid item xs={12} style={flex}>
        <ButtonWithIcon
          iconRight
          className={props.bitrixFormClassName}
          icon={faChevronDoubleRight}
          variant="raised"
          color="secondary"
          style={{ marginLeft: 'auto' }}
        >
          <I18nMessage
            message={messages.contactPharmacy}
            values={{ pharmacyName: props.noPartnerPharmacy.name }}
          />
        </ButtonWithIcon>
      </Grid>
      <Grid item xs={12} style={flex}>
        <Typography variant="caption" style={{ marginLeft: 'auto' }}>
          <I18nMessage
            message={messages.contactNoPartnerPharmacyInfoText}
            values={{ brand: BRAND_NAME }}
          />
        </Typography>
      </Grid>
    </Grid>
  </React.Fragment>
);

const enhance = compose(
  pure,
  withState('bitrixFormClassName', 'setBitrixFormClassName', undefined)
);

const ContactNoPartnerPharmacyButton = enhance(
  InnerContactNoPartnerPharmacyButton
);

ContactNoPartnerPharmacyButton.propTypes = {
  noPartnerPharmacy: PropTypes.object.isRequired,
};

ContactNoPartnerPharmacyButton.defaultProps = {};

InnerContactNoPartnerPharmacyButton.propTypes = {
  ...ContactNoPartnerPharmacyButton.propTypes,
};

InnerContactNoPartnerPharmacyButton.defaultProps = {};

export default ContactNoPartnerPharmacyButton;
