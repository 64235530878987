import PropTypes from 'prop-types';
import React from 'react';
import {
  fullMaxWidth,
  marginAuto,
} from '../../../../constants/styles/commonClasses';
import dimdiImg from '../assets/dimdi_logo.png';

const PharmacyDimdi = ({ url }) => (
  <a style={marginAuto} target="_blank" rel="noopener" href={url}>
    <img style={fullMaxWidth} src={dimdiImg} alt={'dimdi'} />
  </a>
);

PharmacyDimdi.propTypes = {
  url: PropTypes.string.isRequired,
};

export default PharmacyDimdi;
