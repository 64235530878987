import { switchF } from '@apoly-42/apoly-utils';
import moment from 'moment-timezone/moment-timezone';
import PropTypes from 'prop-types';
import { defineMessages, injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { commonMessages } from '../../constants/messages/commonMessages';
import { selectGermanServerTimeMoment } from '../../redux/serverTime/serverTime';
import {
  isNextDay,
  isSameDay,
  timeObjFromMoment,
} from '../../utilities/moment/momentUtilities';
import { TIME_SPAN_TYPE_ABSOLUTE } from '../../utilities/pharmacyTimes/timehandlingUtilities';

const messages = defineMessages({
  absoluteDeliveryHours: {
    id: 'absoluteDeliveryHours',
    defaultMessage:
      'Delivery within {numberOfHours, plural, one {one hour} other {{numberOfHours} hours}} after order',
  },
  absoluteDeliveryMinutes: {
    id: 'absoluteDeliveryMinutes',
    defaultMessage:
      'Delivery within {numberOfMinutes, plural, one {one minute} other {{numberOfMinutes} minutes}} after order',
  },
  absoluteDeliveryHoursAndMinutes: {
    id: 'absoluteDeliveryHoursAndMinutes',
    defaultMessage:
      'Delivery within {numberOfHours, plural, one {one hour} other {{numberOfHours} hours}} and {numberOfMinutes, plural, one {one minute} other {{numberOfMinutes} minutes}} after order',
  },
  deliveryFromToToday: {
    id: 'deliveryFromToToday',
    defaultMessage:
      'Delivery today ({deliveryDate}), between {deliveryFrom} and {deliveryTo}',
  },
  deliveryFromToTomorrow: {
    id: 'deliveryFromToTomorrow',
    defaultMessage:
      'Delivery tomorrow ({deliveryDate}), between {deliveryFrom} and {deliveryTo}',
  },
  deliveryFromTo: {
    id: 'deliveryFromTo',
    defaultMessage:
      'Delivery on {deliveryDate}, between {deliveryFrom} and {deliveryTo}',
  },
  noDeliveryInformationAvailable: {
    id: 'noDeliveryInformationAvailable',
    defaultMessage:
      'We currently have no information when this pharmacy delivers.',
  },
});

const getI18nDuration = (intl, minutes, hours) => {
  const i18nDurationMessage = message =>
    intl.formatMessage(message, {
      numberOfHours: hours,
      numberOfMinutes: minutes,
    });

  return switchF(
    [
      () => minutes > 0 && hours > 0,
      () => i18nDurationMessage(messages.absoluteDeliveryHoursAndMinutes),
    ],
    [
      () => hours > 0,
      () => i18nDurationMessage(messages.absoluteDeliveryHours),
    ],
    [
      () => minutes > 0,
      () => i18nDurationMessage(messages.absoluteDeliveryMinutes),
    ],
    [() => true, () => intl.formatMessage(commonMessages.now)]
  );
};

const getI18nDeliveryFromTo = (
  intl,
  germanServerTimeMoment,
  deliverFromMoment,
  deliverToMoment
) => {
  const write = message =>
    intl.formatMessage(message, {
      deliveryDate: deliverFromMoment.format('L'),
      deliveryFrom: deliverFromMoment.format('LT'),
      deliveryTo: deliverToMoment.format('LT'),
    });

  return switchF(
    [
      () => isSameDay(germanServerTimeMoment, deliverFromMoment),
      () => write(messages.deliveryFromToToday),
    ],
    [
      () => isNextDay(germanServerTimeMoment, deliverFromMoment),
      () => write(messages.deliveryFromToTomorrow),
    ],
    [() => true, () => write(messages.deliveryFromTo)]
  );
};

const InnerPharmacyDeliveryTimeSpan = props => {
  if (!props.courierTimeSpan) {
    return props.intl.formatMessage(messages.noDeliveryInformationAvailable);
  }

  if (props.courierTimeSpan.timeSpanType === TIME_SPAN_TYPE_ABSOLUTE) {
    const deliverWithInMoment = moment(
      props.courierTimeSpan.deliveryWithin,
      'HH:mm:ss'
    );
    const deliveryTo = props.absoluteDeliveryStartsAtMoment
      .clone()
      .add(timeObjFromMoment(deliverWithInMoment));

    return props.germanServerTimeMoment.isSame(
      props.absoluteDeliveryStartsAtMoment
    )
      ? getI18nDuration(
          props.intl,
          deliverWithInMoment.minutes(),
          deliverWithInMoment.hours()
        )
      : getI18nDeliveryFromTo(
          props.intl,
          props.germanServerTimeMoment,
          props.absoluteDeliveryStartsAtMoment,
          deliveryTo
        );
  }

  return getI18nDeliveryFromTo(
    props.intl,
    props.germanServerTimeMoment,
    props.deliveryFromMoment,
    props.deliveryToMoment
  );
};

const mapStateToProps = state => ({
  germanServerTimeMoment: selectGermanServerTimeMoment(state),
});

const enhance = compose(
  injectIntl,
  connect(mapStateToProps)
);

const PharmacyDeliveryTimeSpan = enhance(InnerPharmacyDeliveryTimeSpan);

PharmacyDeliveryTimeSpan.propTypes = {
  courierTimeSpan: PropTypes.object,
  deliveryFromMoment: PropTypes.object,
  deliveryToMoment: PropTypes.object,
  absoluteDeliveryStartsAtMoment: PropTypes.object,
};

PharmacyDeliveryTimeSpan.defaultProps = {
  courierTimeSpan: null,
  deliveryFromMoment: null,
  deliveryToMoment: null,
  absoluteDeliveryStartsAtMoment: null,
};

InnerPharmacyDeliveryTimeSpan.propTypes = {
  germanServerTimeMoment: PropTypes.object.isRequired,
  intl: PropTypes.object.isRequired,
  ...PharmacyDeliveryTimeSpan.propTypes,
};

export default PharmacyDeliveryTimeSpan;
