import { I18nMessage } from '@apoly-42/apoly-components';
import { faGlobe, faShippingFast } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Avatar, CardHeader, withStyles } from '@material-ui/core';
import PropTypes from 'prop-types';
import React from 'react';
import { defineMessages } from 'react-intl';
import { compose } from 'recompose';

export const noPartnerCtaHeaderMessages = defineMessages({
  contactOnline: {
    id: 'contactOnline',
    defaultMessage: 'contact online',
  },
  reachPharmacyOnline: {
    id: 'reachPharmacyOnline',
    defaultMessage: 'Reach {pharmacyName} directly online',
  },
  orderNowOnline: {
    id: 'orderNowOnline',
    defaultMessage: 'order now online',
  },
  atPharmacy: {
    id: 'atPharmacy',
    defaultMessage: 'at a pharmacy near by {zipWithCity}',
  },
});

const nearbyAddress = address => (
  <I18nMessage
    message={noPartnerCtaHeaderMessages.atPharmacy}
    values={{ zipWithCity: `${address.zip} ${address.city}` }}
  />
);

const reachPharmacyOnline = pharmacyName => (
  <I18nMessage
    message={noPartnerCtaHeaderMessages.reachPharmacyOnline}
    values={{ pharmacyName }}
  />
);

const avatarWithIcon = (className, icon) => (
  <Avatar className={className}>
    <FontAwesomeIcon icon={icon} />
  </Avatar>
);

const styles = theme => ({
  avatar: {
    backgroundColor: theme.palette.primary.main,
  },
});

const InnerNoPartnerPharmacyCtaCardHeader = props => (
  <React.Fragment>
    {!props.searchedPharmacies && (
      <CardHeader
        avatar={<Avatar className={props.classes.avatar} />}
        title="..."
        subheader="..."
      />
    )}

    {props.searchedPharmacies &&
      props.searchedPharmacies.length > 0 && (
        <CardHeader
          avatar={avatarWithIcon(props.classes.avatar, faShippingFast)}
          title={
            <I18nMessage message={noPartnerCtaHeaderMessages.orderNowOnline} />
          }
          subheader={nearbyAddress(props.noPartnerPharmacy.address)}
        />
      )}

    {props.searchedPharmacies &&
      props.searchedPharmacies.length <= 0 && (
        <CardHeader
          avatar={avatarWithIcon(props.classes.avatar, faGlobe)}
          title={
            <I18nMessage message={noPartnerCtaHeaderMessages.contactOnline} />
          }
          subheader={reachPharmacyOnline(props.noPartnerPharmacy.name)}
        />
      )}
  </React.Fragment>
);

const enhance = compose(withStyles(styles));

const NoPartnerPharmacyCtaCardHeader = enhance(
  InnerNoPartnerPharmacyCtaCardHeader
);

NoPartnerPharmacyCtaCardHeader.propTypes = {};

NoPartnerPharmacyCtaCardHeader.defaultProps = {};

InnerNoPartnerPharmacyCtaCardHeader.propTypes = {
  classes: PropTypes.object.isRequired,
  ...NoPartnerPharmacyCtaCardHeader.propTypes,
};

InnerNoPartnerPharmacyCtaCardHeader.defaultProps = {};

export default NoPartnerPharmacyCtaCardHeader;
