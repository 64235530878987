import { I18nMessage } from '@apoly-42/apoly-components';
import { ButtonWithIcon } from '@apoly-42/material-ui';
import { faChevronDoubleRight } from '@fortawesome/pro-solid-svg-icons';
import { Grid, Typography } from '@material-ui/core';
import React from 'react';
import { defineMessages } from 'react-intl';
import FooterHeadline from './FooterHeadline';

const messages = defineMessages({
  blog: {
    id: 'blog',
    defaultMessage: 'Blog',
  },
  alwaysUpToDateWithBlog: {
    id: 'alwaysUpToDateWithBlog',
    defaultMessage: 'Stay always up to date with our Blog!',
  },
  readBlogPostsNow: {
    id: 'readBlogPostsNow',
    defaultMessage: 'read blog posts now',
  },
});

const Blog = () => (
  <Grid container spacing={16}>
    <Grid item xs={12}>
      <FooterHeadline>
        <I18nMessage message={messages.blog} />
      </FooterHeadline>
    </Grid>
    <Grid item xs={12}>
      <Typography>
        <I18nMessage message={messages.alwaysUpToDateWithBlog} />
      </Typography>
    </Grid>
    <Grid item xs={12}>
      <ButtonWithIcon
        icon={faChevronDoubleRight}
        iconRight
        component="a"
        rel="noopener"
        target="_blank"
        href="https://www.apoly.de/blog/"
        color="primary"
        variant="raised"
      >
        <I18nMessage message={messages.readBlogPostsNow} />
      </ButtonWithIcon>
    </Grid>
  </Grid>
);

export default Blog;
