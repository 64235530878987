import { I18nMessage, withToggleState } from '@apoly-42/apoly-components';
import { onPropsDidUpdate } from '@apoly-42/apoly-utils';
import {
  ButtonWithIcon,
  GridRow,
  rowTypes,
  TextWithIconCaption,
} from '@apoly-42/material-ui';
import {
  faChevronDoubleRight,
  faClock,
  faMapMarkerAlt,
  faShippingFast,
  faUserMd,
} from '@fortawesome/pro-regular-svg-icons';
import { faChevronDown } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  ExpansionPanel,
  ExpansionPanelDetails,
  ExpansionPanelSummary,
  Grid,
  Typography,
} from '@material-ui/core';
import PropTypes from 'prop-types';
import React from 'react';
import { defineMessages } from 'react-intl';
import { Link } from 'react-router-dom';
import { compose } from 'recompose';
import apoA from '../../../../constants/assets/apo-logo-big.jpg';
import apoStab from '../../../../constants/assets/apo-stab.png';
import {
  flex,
  marginTopBottomAuto,
  verticalCenteredInFlex,
} from '../../../../constants/styles/commonClasses';
import { pharmacyShopRouteLocal } from '../../../../routes/paths';
import { localInformationMessages } from '../../../pharmacyBase/LocalPharmacyInformation';
import NextCourierTimeSpan from '../../../pharmacyDeliveryTimes/NextCourierTimeSpan';
import NextPharmacyOpeningTime from '../../../pharmacyOpeningTImes/NextPharmacyOpeningTime';
import { partnerPharmacyCtaMessages } from '../../pharmacySeo/PartnerPharmacyCta';

export const getPharmacyPic = pharmacy => pharmacy.logoUrl || apoStab;

export const toAddressString = address =>
  `${address.street}, ${address.zip} ${address.city}`;

export const pharmacyExpansionPanel = defineMessages({
  isOpen: {
    id: 'isOpen',
    defaultMessage: 'is open?',
  },
  nextDelivery: {
    id: 'nextDelivery',
    defaultMessage: 'next delivery',
  },
});

const InnerPharmacyExpansionPanel = props => (
  <ExpansionPanel expanded={props.isExpanded} onChange={props.toggleIsExpanded}>
    <ExpansionPanelSummary
      expandIcon={<FontAwesomeIcon icon={faChevronDown} />}
    >
      <Grid container spacing={16}>
        <Grid item style={{ display: 'flex', height: 120 }} xs={12} sm={4}>
          <img
            style={{ margin: 'auto', maxHeight: '100%', maxWidth: '100%' }}
            src={getPharmacyPic(props.enhancedPharmacy.pharmacy)}
            alt={props.enhancedPharmacy.pharmacy.name}
          />
        </Grid>
        <Grid item style={flex} xs={12} sm={8}>
          <div style={marginTopBottomAuto}>
            {props.defaultExpanded ? (
              <div style={flex}>
                <img
                  style={{ margin: 'auto 8px', width: 40, height: 40 }}
                  src={apoA}
                  alt="apotheken-A"
                />
                <Typography style={verticalCenteredInFlex} variant="headline">
                  {props.enhancedPharmacy.pharmacy.name}
                </Typography>
              </div>
            ) : (
              <React.Fragment>
                <Typography variant="title" style={{ marginBottom: 8 }}>
                  {props.enhancedPharmacy.pharmacy.name}
                </Typography>
                <Typography>
                  <NextCourierTimeSpan
                    enhancedPharmacy={props.enhancedPharmacy}
                  />
                </Typography>
              </React.Fragment>
            )}
          </div>
        </Grid>
      </Grid>
    </ExpansionPanelSummary>
    <ExpansionPanelDetails>
      <Grid container spacing={16}>
        <GridRow item type={rowTypes.paragraph} xs={12}>
          <Grid container spacing={16}>
            <Grid item xs={12} sm={6} style={flex}>
              <TextWithIconCaption
                style={marginTopBottomAuto}
                captionMessage={localInformationMessages.addressLabel}
                icon={faMapMarkerAlt}
                text={toAddressString(props.enhancedPharmacy.pharmacy.address)}
              />
            </Grid>
            <Grid item xs={12} sm={6} style={flex}>
              <TextWithIconCaption
                style={marginTopBottomAuto}
                captionMessage={localInformationMessages.owner}
                icon={faUserMd}
                text={props.enhancedPharmacy.pharmacy.owner}
              />
            </Grid>
          </Grid>
        </GridRow>
        <GridRow item type={rowTypes.paragraph} xs={12}>
          <Grid container spacing={16}>
            <Grid item xs={12} sm={6} style={flex}>
              <TextWithIconCaption
                style={marginTopBottomAuto}
                captionMessage={pharmacyExpansionPanel.isOpen}
                icon={faClock}
                text={
                  <NextPharmacyOpeningTime
                    enhancedPharmacy={props.enhancedPharmacy}
                  />
                }
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextWithIconCaption
                style={marginTopBottomAuto}
                captionMessage={pharmacyExpansionPanel.nextDelivery}
                icon={faShippingFast}
                text={
                  <Typography
                    component="span"
                    color={props.defaultExpanded ? 'primary' : undefined}
                  >
                    <NextCourierTimeSpan
                      enhancedPharmacy={props.enhancedPharmacy}
                    />
                  </Typography>
                }
              />
            </Grid>
          </Grid>
        </GridRow>
        <Grid item xs={12} style={flex}>
          <ButtonWithIcon
            icon={faChevronDoubleRight}
            iconRight
            component={Link}
            to={pharmacyShopRouteLocal(props.enhancedPharmacy.pharmacy.urlCode)}
            style={{ marginLeft: 'auto' }}
            variant="raised"
            color="secondary"
            size="large"
          >
            <I18nMessage message={partnerPharmacyCtaMessages.goToShop} />
          </ButtonWithIcon>
        </Grid>
      </Grid>
    </ExpansionPanelDetails>
  </ExpansionPanel>
);

const enhance = compose(
  withToggleState(
    'isExpanded',
    'toggleIsExpanded',
    props => props.defaultExpanded
  ),
  onPropsDidUpdate(
    ['defaultExpanded'],
    props =>
      props.defaultExpanded !== props.isExpanded && props.toggleIsExpanded()
  )
);

const PharmacyExpansionPanel = enhance(InnerPharmacyExpansionPanel);

PharmacyExpansionPanel.propTypes = {
  defaultExpanded: PropTypes.bool.isRequired,
  enhancedPharmacy: PropTypes.object.isRequired,
};

PharmacyExpansionPanel.defaultProps = {};

InnerPharmacyExpansionPanel.propTypes = {
  isExpanded: PropTypes.bool.isRequired,
  toggleIsExpanded: PropTypes.func.isRequired,
  ...PharmacyExpansionPanel.propTypes,
};

export default PharmacyExpansionPanel;
