import {
  Typography,
  Button,
  CssBaseline,
  Paper,
  TextField,
} from '@material-ui/core';
import React, { Component } from 'react';
// eslint-disable-next-line import/no-extraneous-dependencies
import { hot } from 'react-hot-loader';
import { IntlProvider } from 'react-intl';
import CheckRedirectResultFromProviderSignIn from '../containers/auth/CheckRedirectResultFromProviderSignIn';
import HandleCookieConsent from '../containers/HandleCookieConsent';
import LanguageProvider from '../containers/LanguageProvider';
import ScrollToTopOnTransition from '../containers/ScrollToTopOnTransition';
import ObserveLoginStateWrapper from './auth/ObserveLoginStateWrapper';
import DataProtectionAndCookieConsent from './DataProtectionAndCookieConsent';
import ErrorBoundary from './ErrorBoundary';
import JssAppProvider from './JssAppProvider';
import AppMuiThemeProvider from './layout/AppMuiTheme';
import AppPage from './pages/AppPage';
import firebase from 'firebase/app';
import 'firebase/auth';

const config = {
  apiKey: 'AIzaSyBeKOiWwVWTlFo-45mTgICZmbG8SPaN9g4',
  authDomain: 'apoly-dev.firebaseapp.com',
  databaseURL: 'https://apoly-dev.firebaseio.com',
  projectId: 'apoly-dev',
  storageBucket: 'apoly-dev.appspot.com',
  messagingSenderId: '542742603419',
};

const authApp = firebase.initializeApp(config, 'auth-app');

class FirebaseAuth extends React.Component {
  state = {
    email: '',
    password: '',
    loading: false,
    error: false,
  };

  handleChange = e => this.setState({ [e.target.name]: e.target.value });

  handleSubmit = e => {
    this.setState({ loading: true });

    authApp
      .auth()
      .signInWithEmailAndPassword(this.state.email, this.state.password)
      .catch(e => {
        console.error(e);
        this.setState({ loading: false, error: true });
      });

    e.preventDefault();
  };

  render() {
    return (
      <div style={{ height: '100vh', width: '100vw', display: 'flex' }}>
        <Paper style={{ width: 400, margin: 'auto', padding: 16 }}>
          <form
            onSubmit={this.handleSubmit}
            style={{ display: 'grid', gridGap: 16 }}
          >
            <TextField
              required={true}
              onChange={this.handleChange}
              label="E-Mail"
              name="email"
              type="text"
            />
            <TextField
              required={true}
              onChange={this.handleChange}
              label="Passwort"
              name="password"
              type="password"
            />
            <Button
              type="submit"
              variant="raised"
              color="secondary"
              disabled={this.state.loading}
            >
              submit
            </Button>
            {this.state.error && <Typography>Ungültiges Passwort</Typography>}
          </form>
        </Paper>
      </div>
    );
  }
}

class InnerApp extends Component {
  constructor(props) {
    super(props);

    this.state = {
      user: authApp.auth().currentUser,
    };

    authApp.auth().onAuthStateChanged(user => this.setState({ user }));
  }

  render() {
    if (!this.state.user) {
      return <FirebaseAuth />;
    }

    return (
      <IntlProvider locale="en">
        <ErrorBoundary>
          <JssAppProvider>
            <LanguageProvider>
              <AppMuiThemeProvider>
                <ErrorBoundary>
                  <React.Fragment>
                    <CssBaseline />

                    <ScrollToTopOnTransition />

                    <HandleCookieConsent />

                    <ObserveLoginStateWrapper>
                      <CheckRedirectResultFromProviderSignIn />
                      <AppPage />

                      <DataProtectionAndCookieConsent />
                    </ObserveLoginStateWrapper>
                  </React.Fragment>
                </ErrorBoundary>
              </AppMuiThemeProvider>
            </LanguageProvider>
          </JssAppProvider>
        </ErrorBoundary>
      </IntlProvider>
    );
  }
}

const App =
  process.env.NODE_ENV === 'development' ? hot(module)(InnerApp) : InnerApp;

export default App;
