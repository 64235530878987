import { I18nMessage } from '@apoly-42/apoly-components';
import { ButtonWithIcon, CustomTooltip } from '@apoly-42/material-ui';
import { faTruck } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Hidden, withStyles } from '@material-ui/core';
import PropTypes from 'prop-types';
import React from 'react';
import { injectIntl } from 'react-intl';
import { compose } from 'recompose';
import { commonMessages } from '../../../../../constants/messages/commonMessages';
import PharmacyDelivery from '../../../../pharmacyDeliveryTimes/PharmacyDelivery';

const styles = {
  buttonXsTooltip: {
    margin: 'auto 8px',
    '@media (max-width: 600px)': {
      minWidth: 0,
    },
    cursor: 'default',
  },
};

const DeliveryTimeSpanTooltip = ({
  classes,
  color,
  style,
  enhancedPharmacy,
  intl: { formatMessage },
}) => (
  <CustomTooltip
    id="delivery"
    title={
      <PharmacyDelivery
        enhancedNextCourierTimeSpans={enhancedPharmacy.nextCourierTimeSpans}
      />
    }
    placement="bottom"
  >
    <span>
      <Hidden smDown>
        <ButtonWithIcon
          icon={faTruck}
          disableRipple
          className={classes.buttonXsTooltip}
          color={color}
          style={style}
          aria-label={formatMessage(commonMessages.delivery)}
        >
          <I18nMessage message={commonMessages.delivery} />
        </ButtonWithIcon>
      </Hidden>
      <Hidden mdUp>
        <Button
          style={{
            marginLeft: 8,
            marginRight: 8,
            minWidth: 0,
            ...style,
          }}
          aria-label={formatMessage(commonMessages.delivery)}
          disableRipple
          color={color}
        >
          <FontAwesomeIcon icon={faTruck} />
        </Button>
      </Hidden>
    </span>
  </CustomTooltip>
);

DeliveryTimeSpanTooltip.propTypes = {
  intl: PropTypes.object.isRequired,
  classes: PropTypes.object.isRequired,
  enhancedPharmacy: PropTypes.object.isRequired,
  style: PropTypes.object.isRequired,
  color: PropTypes.string.isRequired,
};

const enhance = compose(
  withStyles(styles),
  injectIntl
);

export default enhance(DeliveryTimeSpanTooltip);
