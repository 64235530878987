import { I18nMessage } from '@apoly-42/apoly-components';
import { ButtonWithIcon } from '@apoly-42/material-ui';
import { faPhone, faSignIn, faSignOut } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Grid, Toolbar, Typography } from '@material-ui/core';
import { css } from 'emotion';
import React from 'react';
import { Link } from 'react-router-dom';
import ShoppingCartButton from '../../../../components/shoppingCartButton/ShoppingCartButton';
import { commonMessages } from '../../../../constants/messages/commonMessages';
import { flex } from '../../../../constants/styles/commonClasses';
import {
  loginSetPharmacyPath,
  registerSetPharmacyPath,
} from '../../../../routes/paths';
import { Pharmacy } from '../../../../dbTypes';
import firebase from 'firebase';

const margin = css`
  margin: auto 8px;
`;

const zipsAndPhone = css`
  display: flex;
  flex-direction: column;
`;

export const getZipString = (pharmacyZips: string[]) => {
  let zipStr = '';
  const pharmacyZipsLength = pharmacyZips.length;
  pharmacyZips.map(
    (zip: string, index: number) =>
      (zipStr += ` ${zip}${index + 1 < pharmacyZipsLength ? ',' : ''}`)
  );
  return zipStr;
};

interface ToolBarTopProps {
  user: firebase.User;
  pharmacy: Pharmacy;
  basePath: string;
  logOut: () => void;
}

export const ToolBarTop: React.SFC<ToolBarTopProps> = props => (
  <Grid container justify="center" spacing={0}>
    <Grid item xs={12} lg={10} style={{ flexGrow: 1 }}>
      <Toolbar>
        <div
          style={{
            color: 'inherit',
            flex: 1,
          }}
        >
          <div>
            <div className={zipsAndPhone}>
              <Typography
                variant={'caption'}
                className={margin}
                style={{ marginBottom: 8 }}
                color="inherit"
              >
                <I18nMessage
                  message={commonMessages.deliveryAreaZips}
                  values={{ zips: getZipString(props.pharmacy.availableZips) }}
                />
              </Typography>
              <div style={flex}>
                <div className={margin}>
                  <FontAwesomeIcon icon={faPhone} />
                </div>
                <Typography className={margin} color="inherit">
                  {props.pharmacy.phone}
                </Typography>
              </div>
            </div>
          </div>
        </div>
        <div style={flex} />
        <Button
          color={'inherit'}
          component={Link}
          to={registerSetPharmacyPath(props.basePath)}
        >
          <I18nMessage message={commonMessages.register} />
        </Button>
        {props.user ? (
          <ButtonWithIcon
            color={'inherit'}
            icon={faSignOut}
            onClick={props.logOut}
          >
            <I18nMessage message={commonMessages.logOut} />
          </ButtonWithIcon>
        ) : (
          <ButtonWithIcon
            color={'inherit'}
            icon={faSignIn}
            component={Link}
            to={loginSetPharmacyPath(props.basePath)}
          >
            <I18nMessage message={commonMessages.login} />
          </ButtonWithIcon>
        )}
        <ShoppingCartButton showAdditionalText={true} />
      </Toolbar>
    </Grid>
  </Grid>
);
