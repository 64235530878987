import { I18nMessage } from '@apoly-42/apoly-components';
import {
  faEnvelope,
  faHome,
  faInfoCircle,
  faRetweet,
  faSignIn,
  faSignOut,
} from '@fortawesome/pro-solid-svg-icons';
import {
  AppBar,
  Drawer,
  Hidden,
  List,
  ListItem,
  Toolbar,
  withStyles,
} from '@material-ui/core';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { commonMessages } from '../../../../constants/messages/commonMessages';
import { selectUser } from '../../../../redux/auth/reducer';
import {
  choosePharmacyPath,
  contactPath,
  faqPath,
  landingPath,
  loginPath,
} from '../../../../routes/paths';
import BrandName from '../navbar/navBarComponents/BrandName';
import ChangeLocaleDrawer from './drawerComponents/ChangeLocaleDrawer';
import CloseDrawerButton from './drawerComponents/CloseDrawerButton';
import ListItemButtonWithIcon from './ListItemButtonWithIcon';
import ListItemLinkWithIcon from './ListItemLinkWithIcon';

const styles = {
  drawer: {
    minWidth: 250,
    maxWidth: 300,
  },
};

const InnerRightDrawer = ({
  classes,
  user,
  open,
  handleDrawer,
  logOutUser,
}) => (
  <Drawer anchor="right" open={open} onClose={handleDrawer}>
    <div tabIndex={0} role="button" onKeyDown={handleDrawer}>
      <AppBar position="static" color="primary">
        <Toolbar>
          <BrandName align="center" url={landingPath()} />
        </Toolbar>
      </AppBar>
      <List className={classes.drawer}>
        <Hidden smUp>
          <CloseDrawerButton handleDrawer={handleDrawer} />
        </Hidden>
        {user ? (
          <ListItemButtonWithIcon
            text={<I18nMessage message={commonMessages.logOut} />}
            icon={faSignOut}
            onClickEvent={logOutUser}
          />
        ) : (
          <ListItemLinkWithIcon
            text={<I18nMessage message={commonMessages.login} />}
            icon={faSignIn}
            link={loginPath()}
          />
        )}
        <ListItemLinkWithIcon
          text={<I18nMessage message={commonMessages.home} />}
          icon={faHome}
          link={landingPath()}
        />
        <ListItemLinkWithIcon
          text={<I18nMessage message={commonMessages.findPharmacy} />}
          icon={faRetweet}
          link={choosePharmacyPath()}
        />
        <ListItemLinkWithIcon
          text={<I18nMessage message={commonMessages.faq} />}
          icon={faInfoCircle}
          link={faqPath()}
        />
        <ListItemLinkWithIcon
          text={<I18nMessage message={commonMessages.contact} />}
          icon={faEnvelope}
          link={contactPath()}
        />
        <ListItem divider>
          <ChangeLocaleDrawer />
        </ListItem>
        <CloseDrawerButton handleDrawer={handleDrawer} />
      </List>
    </div>
  </Drawer>
);

const mapStateToProps = state => ({
  user: selectUser(state),
});

const enhance = compose(
  withStyles(styles),
  connect(mapStateToProps)
);

const RightDrawer = enhance(InnerRightDrawer);

RightDrawer.propTypes = {
  open: PropTypes.bool.isRequired,
  handleDrawer: PropTypes.func.isRequired,
  logOutUser: PropTypes.func.isRequired,
};

InnerRightDrawer.propTypes = {
  classes: PropTypes.object.isRequired,
  user: PropTypes.object,
  ...RightDrawer.propTypes,
};

InnerRightDrawer.defaultProps = {
  user: null,
};

export default RightDrawer;
