import {
  DELIVERY_AREA_BOX,
  EASY_ORDER_BOX,
} from '../customInfoBox/CustomInfoBox';
import React from 'react';
import landingSliderPic1 from './koenigs_apo_slider1.jpeg';
import landingSliderPic2 from './koenigs_apo_slider2.jpeg';
import clicAndCollect from './click_and_collect.png';
import prescriptionService from './prescription_service.png';

export const koenigsApoLandingSlider = [landingSliderPic1, landingSliderPic2];

export const getkoenigsApoInfoBoxContent = koenigsApoCustomPalette => [
  {
    boxType: 'custom',
    headline: "Ihre König's Apotheken",
    infoText: 'können jetzt noch viel mehr!',
    image: clicAndCollect,
    supImageText: '',
    textColor: '#fff',
  },
  {
    boxType: 'custom',
    headline: 'Click & Collect Vorbestellung',
    infoText:
      'Einfach Medikamente online vorbestellen und fertig verpackt in Ihrer Apotheke abholen.',
    image: '',
    supImageText: '',
    textColor: '#fff',
  },
  {
    boxType: 'custom',
    headline: 'Unser Rezeptservice*',
    infoText: (
      <span>
        'Wir holen Ihr Rezept an Ihrer Wunschadresse - bei Ihnen zu Hause, bei
        der Arbeit oder auch beim Arzt{' '}
        <span style={{ color: koenigsApoCustomPalette.secondary.main }}>
          kostenlos
        </span>{' '}
        ab und liefern Ihre Medikamente{' '}
        <span style={{ color: koenigsApoCustomPalette.secondary.main }}>
          kostenlos
        </span>{' '}
        zu Ihnen.'{' '}
      </span>
    ),
    image: prescriptionService,
    supImageText: (
      <span style={{ color: koenigsApoCustomPalette.secondary.main }}>
        Kostenlose Abholung Ihres Rezeptes
      </span>
    ),
    textColor: '#fff',
  },
  {
    boxType: EASY_ORDER_BOX,
    headline: '',
    infoText: '',
    image: '',
    supImageText: '',
    textColor: '#fff',
  },
  {
    boxType: DELIVERY_AREA_BOX,
    headline: '',
    infoText: '',
    image: '',
    supImageText: '',
    textColor: '#fff',
  },
];
