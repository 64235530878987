import { I18nMessage } from '@apoly-42/apoly-components';
import PropTypes from 'prop-types';
import React from 'react';
import { defineMessages } from 'react-intl';
import PharmacyDeliveryTimeSpan from './PharmacyDeliveryTimeSpan';

const messages = defineMessages({
  noDelivery: {
    id: 'noDelivery',
    defaultMessage: 'This pharmacy does not currently offer delivery.',
  },
});

const PharmacyDelivery = ({ enhancedNextCourierTimeSpans }) =>
  enhancedNextCourierTimeSpans.length > 0 ? (
    <span color="inherit">
      <PharmacyDeliveryTimeSpan
        courierTimeSpan={enhancedNextCourierTimeSpans[0]}
        deliveryFromMoment={enhancedNextCourierTimeSpans[0].deliveryFromMoment}
        deliveryToMoment={enhancedNextCourierTimeSpans[0].deliveryToMoment}
        absoluteDeliveryStartsAtMoment={
          enhancedNextCourierTimeSpans[0].absoluteDeliveryStartsAtMoment
        }
      />
    </span>
  ) : (
    <span color="inherit">
      <I18nMessage message={messages.noDelivery} />
    </span>
  );

PharmacyDelivery.propTypes = {
  enhancedNextCourierTimeSpans: PropTypes.array.isRequired,
};

export default PharmacyDelivery;
