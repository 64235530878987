import PropTypes from 'prop-types';
import React from 'react';
import ShoppingCartButtonView from './ShoppingCartButtonView';
import ShoppingCartButtonWithAdditionalTextView from './ShoppingCartButtonWithAdditionalTextView';

const ShoppingCartButton = ({ showAdditionalText }) =>
  showAdditionalText ? (
    <ShoppingCartButtonWithAdditionalTextView />
  ) : (
    <ShoppingCartButtonView />
  );

ShoppingCartButton.propTypes = {
  showAdditionalText: PropTypes.bool,
};

ShoppingCartButton.defaultProps = {
  showAdditionalText: false,
};

export default ShoppingCartButton;
