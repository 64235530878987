import { I18nMessage } from '@apoly-42/apoly-components';
import { headIfExists, switchF } from '@apoly-42/apoly-utils';
import { faCheck, faTimes } from '@fortawesome/pro-solid-svg-icons';
import { complement, curry, prop, sortBy } from 'ramda';
import React from 'react';
import { POSTAL_COST } from '../../constants';
import productImageDefault from '../../constants/assets/apolyPille_default.png';
import { commonMessages } from '../../constants/messages/commonMessages';
import {
  singleProduct,
  singleProductSetPharmacyPath,
} from '../../routes/paths';

export const getPricePerPiece = (price, packageSize) =>
  packageSize &&
  (Math.round((Number(price) / Number(packageSize)) * 100) / 100).toFixed(2);

export const getPackageUnit = packageAmountText => {
  const packageAmount = packageAmountText.split(' ');
  return packageAmount[packageAmount.length - 1];
};

export const articlesForMedicineId = (articles, medicineId) =>
  articles.filter(
    article => article.package.medicine.medicineId === medicineId
  );

const isActiveAgent = agent => Number(agent.isActive) === 1;

export const getActiveComponents = agents => agents.filter(isActiveAgent);

export const getAdditives = agents => agents.filter(complement(isActiveAgent));

export const getProductTotalPrice = (price, count) =>
  Number((Math.round(Number(price) * Number(count) * 100) / 100).toFixed(2));

export const sortPackageSizeArrayByRrpAsc = sortBy(prop('rrp'));

export const sortArrayByPropDesc = packageSizes =>
  sortPackageSizeArrayByRrpAsc(packageSizes).reverse();

export const checkForDeliveryAvailability = deliveryStatus =>
  deliveryStatus === 'available' ? (
    <I18nMessage message={commonMessages.deliverable} />
  ) : (
    <I18nMessage message={commonMessages.notDeliverable} />
  );

export const checkDeliveryStatus = deliveryStatus =>
  deliveryStatus ? faCheck : faTimes;

export const getProductUrl = (basePath, productUrlCode, packageSize) =>
  basePath
    ? singleProductSetPharmacyPath(basePath, productUrlCode, packageSize)
    : singleProduct(productUrlCode, packageSize);

export const getImageForProduct = (hasPicture, pzn) =>
  Number(hasPicture)
    ? `https://cdn8.apopixx.de/400/web_schraeg_png/${pzn}.png`
    : productImageDefault;

const toUnit = (formatNumber, activeComponent) =>
  activeComponent.amount &&
  activeComponent.unit &&
  `(${formatNumber(Number(activeComponent.amount))} ${
    activeComponent.unit.name
  })`;

export const activeComponentToString = curry(
  (formatNumber, activeComponent) =>
    `${activeComponent.agent.name} ${toUnit(formatNumber, activeComponent) ||
      ''}`
);

export const getArticleWithArticleId = (articles, articleId) => {
  let activeArticle = {};
  articles.map(article => {
    if (article.articleId.toString() === articleId) {
      activeArticle = article;
    }
    return activeArticle;
  });
  return activeArticle;
};

export const getArticleCountFromShoppingCart = (articleId, shoppingCart) =>
  headIfExists(
    shoppingCart.filter(
      shoppingCartItem => shoppingCartItem.articleId === articleId
    )
  ).count;

export const getTotalPrice = (shoppingCartPrice, transmission, deliveryPrice) =>
  switchF(
    [
      () => transmission === 'courier' || transmission === 'postal',
      () => Number(shoppingCartPrice) + Number(deliveryPrice),
    ],
    [() => true, () => Number(shoppingCartPrice)]
  );

export const getShippingCosts = (transmission, deliveryPrice) =>
  switchF(
    [() => transmission === 'courier', () => Number(deliveryPrice)],
    [() => transmission === 'postal', () => POSTAL_COST],
    [() => true, () => 0]
  );
