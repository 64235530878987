import { createContainer, onMountOrUpdate } from '@apoly-42/apoly-utils';
import PropTypes from 'prop-types';
import { tap } from 'ramda';
import { searchPharmaciesByZip } from '../../utilities/apis/apolyApi/apolyApi';
import { wrapHandlerWithGracefulLogging } from '../../utilities/wrapHandlerWithGracefulLogging';

const LoadPharmaciesByZip = createContainer(
  onMountOrUpdate(['zip'], props =>
    Promise.resolve('FIX')
      .then(tap(props.dispose))
      .then(tap(() => props.setIsLoading(true)))
      .then(() => searchPharmaciesByZip(props.zip))
      .then(tap(props.setPharmacies))
      .catch(tap(wrapHandlerWithGracefulLogging(props.onError)))
      .then(tap(() => props.setIsLoading(false)))
  )
);

LoadPharmaciesByZip.propTypes = {
  setIsLoading: PropTypes.func.isRequired,
  zip: PropTypes.string.isRequired,
  setPharmacies: PropTypes.func.isRequired,
  onError: PropTypes.func.isRequired,
  dispose: PropTypes.func,
};

LoadPharmaciesByZip.defaultProps = {
  dispose: () => null,
};

export default LoadPharmaciesByZip;
