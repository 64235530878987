import React from 'react';
import { Route } from 'react-router-dom';
import { LoadChoosePharmacyPage } from '../components/pages/LoadPagesAsync';
import { parseSearchStringIfAvailable } from '../utilities/qsUtilities';

const renderSearchPharmacyRoute = path => (
  <Route
    path={path}
    render={({ location }) => {
      const parsedSearch = parseSearchStringIfAvailable(location.search);

      return (
        <LoadChoosePharmacyPage
          addressStringFromUrl={
            parsedSearch && parsedSearch.q ? parsedSearch.q : ''
          }
        />
      );
    }}
  />
);

export default renderSearchPharmacyRoute;
