import { I18nMessage } from '@apoly-42/apoly-components';
import {
  faComment,
  faEnvelope,
  faHome,
  faHospital,
  faInfoCircle,
  faRetweet,
  faSearch,
  faSignIn,
  faSignOut,
  faTruck,
} from '@fortawesome/pro-solid-svg-icons';
import {
  AppBar,
  Drawer,
  List,
  ListItem,
  ListItemText,
  Toolbar,
  withStyles,
} from '@material-ui/core';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { commonMessages } from '../../../../constants/messages/commonMessages';
import { selectUser } from '../../../../redux/auth/reducer';
import {
  choosePharmacySetPharmacyPath,
  contactPathSetPharmacy,
  deliveryCostsSetPharmacyPath,
  faqSetPharmacyPath,
  landingPath,
  loginSetPharmacyPath,
  medicSearchSetPharmacyPath,
  partnerPharmacyPath,
} from '../../../../routes/paths';
import BrandName from '../navbar/navBarComponents/BrandName';
import ChangeLocaleDrawer from './drawerComponents/ChangeLocaleDrawer';
import CloseDrawerButton from './drawerComponents/CloseDrawerButton';
import ListItemButtonWithIcon from './ListItemButtonWithIcon';
import ListItemLinkWithIcon from './ListItemLinkWithIcon';
import PaymentMethods from './PaymentMethods';
import { pharmacyDrawerMessages } from './RightDrawerSetPharmacy';

const styles = {
  drawer: {
    minWidth: 250,
    maxWidth: 300,
  },
};

const InnerRightDrawerSetPharmacyPostal = ({
  classes,
  user,
  pharmacy,
  basePath,
  open,
  toggleSearchBarAndDrawer,
  logOutUser,
  handleDrawer,
}) => (
  <Drawer anchor="right" open={open} onClose={handleDrawer}>
    <div tabIndex={0} role="button" onKeyDown={handleDrawer}>
      <AppBar position="static" color="primary">
        <Toolbar>
          <BrandName align="center" url={partnerPharmacyPath(basePath)} />
        </Toolbar>
      </AppBar>
      <List className={classes.drawer}>
        <CloseDrawerButton handleDrawer={handleDrawer} />
        <div>
          {user ? (
            <ListItemButtonWithIcon
              text={<I18nMessage message={commonMessages.logOut} />}
              icon={faSignOut}
              onClickEvent={logOutUser}
            />
          ) : (
            <ListItemLinkWithIcon
              text={<I18nMessage message={commonMessages.login} />}
              icon={faSignIn}
              link={loginSetPharmacyPath(basePath)}
            />
          )}
          <ListItemLinkWithIcon
            text={pharmacy.name}
            icon={faHospital}
            link={partnerPharmacyPath(basePath)}
          />
          <ListItemLinkWithIcon
            text={
              <I18nMessage message={commonMessages.deliveryInWholeGermany} />
            }
            secondaryText={
              <I18nMessage message={commonMessages.postalDeliveryGermany} />
            }
            icon={faTruck}
            link={deliveryCostsSetPharmacyPath(basePath)}
          />
          <ListItem>
            <ListItemText
              inset
              primary={
                <I18nMessage message={pharmacyDrawerMessages.paymentMethods} />
              }
            />
          </ListItem>
          <ListItem divider>
            <ListItemText
              primary={
                <PaymentMethods
                  availablePaymentMethods={pharmacy.paymentMethods}
                />
              }
            />
          </ListItem>
        </div>
        <ListItemLinkWithIcon
          text={<I18nMessage message={commonMessages.home} />}
          icon={faHome}
          link={landingPath(basePath)}
        />
        <ListItemLinkWithIcon
          text={<I18nMessage message={pharmacyDrawerMessages.changePharmacy} />}
          icon={faRetweet}
          link={choosePharmacySetPharmacyPath(basePath)}
        />
        <ListItemButtonWithIcon
          text={
            <I18nMessage message={pharmacyDrawerMessages.searchForMedicine} />
          }
          icon={faSearch}
          onClickEvent={toggleSearchBarAndDrawer}
        />
        <ListItemLinkWithIcon
          text={<I18nMessage message={commonMessages.chatBot} />}
          icon={faComment}
          link={medicSearchSetPharmacyPath(basePath)}
        />
        <ListItemLinkWithIcon
          text={<I18nMessage message={commonMessages.faq} />}
          icon={faInfoCircle}
          link={faqSetPharmacyPath(basePath)}
        />
        <ListItemLinkWithIcon
          text={<I18nMessage message={commonMessages.contact} />}
          icon={faEnvelope}
          link={contactPathSetPharmacy(basePath)}
        />
        <ListItem divider>
          <ChangeLocaleDrawer />
        </ListItem>
      </List>
    </div>
  </Drawer>
);

const mapStateToProps = state => ({
  user: selectUser(state),
});

const enhance = compose(
  withStyles(styles),
  connect(mapStateToProps)
);

const RightDrawerSetPharmacyPostal = enhance(InnerRightDrawerSetPharmacyPostal);

RightDrawerSetPharmacyPostal.propTypes = {
  pharmacy: PropTypes.object.isRequired,
  basePath: PropTypes.string.isRequired,
  open: PropTypes.bool.isRequired,
  toggleSearchBarAndDrawer: PropTypes.func.isRequired,
  logOutUser: PropTypes.func.isRequired,
  handleDrawer: PropTypes.func.isRequired,
};

InnerRightDrawerSetPharmacyPostal.propTypes = {
  classes: PropTypes.object.isRequired,
  user: PropTypes.object,
  ...RightDrawerSetPharmacyPostal.propTypes,
};

InnerRightDrawerSetPharmacyPostal.defaultProps = {
  user: null,
  ...RightDrawerSetPharmacyPostal.defaultProps,
};

export default RightDrawerSetPharmacyPostal;
