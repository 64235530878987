import * as React from 'react';
import { defineMessages } from 'react-intl';
import { I18nMessage } from '../app-util-components/I18nMessage';
import { BRAND_NAME } from '../environment';
import { PharmacyTheme } from './PharmacyThemeInterface';

const messages = defineMessages({
  welcomeToPartnerPharmacy: {
    id: 'welcomeToPartnerPharmacy',
    defaultMessage: 'Welcome to your {brand}-partner pharmacy',
  },
});

export const appPalette = {
  primary: {
    light: '#77c2cc',
    main: '#45919b',
    dark: '#03636d',
    contrastText: '#fff',
  },
  secondary: {
    light: '#ffa040',
    main: '#ff6f00',
    dark: '#c43e00',
    contrastText: '#000000',
  },
};

export const apolyLandingSlogan = (
  <I18nMessage
    message={messages.welcomeToPartnerPharmacy}
    values={{ brand: BRAND_NAME }}
  />
);

export const pharmacyDefaults: PharmacyTheme = {
  palette: appPalette,
};
