import { DefaultLoadable } from '@apoly-42/material-ui';
import PropTypes from 'prop-types';

const LoadPharmacyShopLoadable = DefaultLoadable({
  loader: () => import('./LoadGermanCityPictures'),
});

LoadPharmacyShopLoadable.propTypes = {
  setPictureUrl: PropTypes.func.isRequired,
  zip: PropTypes.string.isRequired,
};

export default LoadPharmacyShopLoadable;
