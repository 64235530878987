import React from 'react';
import { Card, Typography } from '@material-ui/core';
import { css } from 'emotion';
import {
  appGrid,
  gridArea,
} from '../../../../constants/styles/cssEmotionClasses';
import { Headline } from '@apoly-42/material-ui';
import { fullMaxWidth } from '../../../../constants/styles/commonClasses';

export const customInfoBoxGrid = css`
  ${appGrid};
  grid-template-areas:
    'headline'
    'infoText'
    'image'
    'supImageText';
  grid-template-columns: 1fr;
`;

export const DELIVERY_AREA_BOX = 'delivery area box';

export const EASY_ORDER_BOX = 'easy order box';

export interface CustomInfoBoxProps {
  infoText?: string | React.ReactElement<any>;
  headline: string | React.ReactElement<any>;
  image?: string;
  supImageText?: string;
  bgColor?: React.CSSProperties['backgroundColor'];
  textColor?: React.CSSProperties['color'];
  headlineColor?: React.CSSProperties['color'];
}

export const CustomInfoBox: React.SFC<CustomInfoBoxProps> = ({
  infoText,
  headline,
  image,
  supImageText,
  bgColor,
  textColor,
  headlineColor,
}) => {
  console.log(infoText, headline, image, supImageText);
  return (
    <Card style={{ padding: 16, backgroundColor: bgColor }}>
      <div className={customInfoBoxGrid}>
        <div className={gridArea('headline')}>
          {headline && (
            <Headline headlineType="h3" style={{ color: headlineColor }}>
              {headline}
            </Headline>
          )}
        </div>
        <div className={gridArea('infoText')}>
          {infoText && (
            <Typography style={{ color: textColor }}>{infoText}</Typography>
          )}
        </div>
        <div className={gridArea('image')}>
          {image && <img style={fullMaxWidth} src={image} />}
        </div>
        <div className={gridArea('supImageText')}>
          {supImageText && (
            <Typography style={{ color: textColor }}>{supImageText}</Typography>
          )}
        </div>
      </div>
    </Card>
  );
};
