import { I18nMessage } from '@apoly-42/apoly-components';
import { ButtonWithIcon } from '@apoly-42/material-ui';
import { faChevronDoubleRight } from '@fortawesome/pro-solid-svg-icons';
import { Grid, Typography } from '@material-ui/core';
import React from 'react';
import { defineMessages } from 'react-intl';
import FooterHeadline from './FooterHeadline';

const messages = defineMessages({
  forPharmacies: {
    id: 'forPharmacies',
    defaultMessage: 'For pharmacies',
  },
  becomePartnerAndPresentPharmacyOnline: {
    id: 'becomePartnerAndPresentPharmacyOnline',
    defaultMessage: 'Become partner for free and present your pharmacy online.',
  },
  discoverMore: {
    id: 'discoverMore',
    defaultMessage: 'Discover more',
  },
});

const BecomePartner = () => (
  <Grid container spacing={16}>
    <Grid item xs={12}>
      <FooterHeadline>
        <I18nMessage message={messages.forPharmacies} />
      </FooterHeadline>
    </Grid>
    <Grid item xs={12}>
      <Typography>
        <I18nMessage message={messages.becomePartnerAndPresentPharmacyOnline} />
      </Typography>
    </Grid>
    <Grid item xs={12}>
      <ButtonWithIcon
        component="a"
        target="_blank"
        rel="noopener"
        href="https://apoly.de/partner-werden.htm"
        color="primary"
        variant="raised"
        icon={faChevronDoubleRight}
        iconRight
      >
        <I18nMessage message={messages.discoverMore} />
      </ButtonWithIcon>
    </Grid>
  </Grid>
);

export default BecomePartner;
