import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ListItem, ListItemIcon, ListItemText } from '@material-ui/core';
import PropTypes from 'prop-types';
import React from 'react';
import { Link } from 'react-router-dom';

const ListItemLinkWithIcon = ({ secondaryText, text, icon, link }) => (
  <ListItem button component={Link} to={link} divider>
    <ListItemIcon>
      <FontAwesomeIcon icon={icon} />
    </ListItemIcon>
    <ListItemText primary={text} secondary={secondaryText} />
  </ListItem>
);

ListItemLinkWithIcon.propTypes = {
  icon: PropTypes.object.isRequired,
  text: PropTypes.any.isRequired,
  link: PropTypes.any.isRequired,
  secondaryText: PropTypes.any,
};

ListItemLinkWithIcon.defaultProps = {
  secondaryText: '',
};

export default ListItemLinkWithIcon;
